import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Layout.css';
import logo from './connaught.png'; // Update the logo path if necessary
import homeIcon from '../pages/images/home.png'; // Your custom home icon image

function Layout({ user, onLogout, openLoginModal, children }) {
    const navigate = useNavigate();

    return (
        <div className="layout">
            <header className="layout-header">
                {/* Logo Section */}
                <div className="layout-logo-container" onClick={() => navigate('/')} role="button">
                    <img src={logo} alt="Connaught Lodge Logo" className="layout-logo" />
                </div>

                {/* Title and Subtitle Section */}
                <div className="layout-title-container">
                    <h1 className="layout-title">Welcome to Connaught Lodge #361 I.C.</h1>
                    <p className="layout-subtitle">
                        Connaught Lodge, under the Provincial Grand Lodge of South Africa Northern I.C, serves as the research lodge for Irish Freemasonry in South Africa.
                        This portal preserves its legacy, and supports Irish Masonic heritage research.
                    </p>
                </div>

                {/* Login and Home Buttons */}
                <div className="layout-buttons">
                    {user ? (
                        <button className="logout-button" onClick={onLogout}>
                            Logout ({user.username})
                        </button>
                    ) : (
                        <button className="login-button" onClick={openLoginModal}>
                            Login
                        </button>
                    )}
                    <button
                        className="home-button"
                        onClick={() => navigate('/')}
                        aria-label="Go to Home"
                    >
                        <img src={homeIcon} alt="Home" className="home-icon" />
                    </button>
                </div>
            </header>
            <main className="layout-content">{children}</main>
        </div>
    );
}

export default Layout;
