import React, { useState, useEffect } from "react";
import axios from "axios";
import "./BuildingDetailsModal.css";
import Select from "react-select"; // Import react-select

function BuildingDetailsModal({ onClose, onSave, onDelete }) {
    const [buildings, setBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [errors, setErrors] = useState({});

    // Fetch buildings on component mount
    useEffect(() => {
        fetchBuildings();
    }, []);

    const fetchBuildings = async () => {
        try {
            const res = await axios.get("/api/buildings");
            setBuildings(res.data);
        } catch (error) {
            console.error("Error fetching buildings:", error);
        }
    };

    // Utility function to convert UTC to SAST
    const convertToSAST = (utcDate) => {
        if (!utcDate) return "";
        const date = new Date(utcDate);
        const offsetInMs = 2 * 60 * 60 * 1000; // SAST is UTC+2
        const sastDate = new Date(date.getTime() + offsetInMs);
        return sastDate.toISOString().split("T")[0]; // Return in YYYY-MM-DD format
    };

    const handleBuildingSelect = async (id) => {
        if (id === "new") {
            setSelectedBuilding(null);
            setFormData({}); // Reset form for a new building
            setIsAddingNew(true);
            return;
        }

        setLoading(true);
        setIsAddingNew(false);

        try {
            const res = await axios.get(`/api/buildings/${id}`);
            const building = res.data;

            const transformedBuilding = {
                ...building,
                established_date: building.established_date
                    ? convertToSAST(building.established_date)
                    : "",
                decommission_date: building.decommission_date
                    ? convertToSAST(building.decommission_date)
                    : "",
            };

            setSelectedBuilding(transformedBuilding);
            setFormData(transformedBuilding);
        } catch (error) {
            console.error("Error fetching building details:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });

        // Clear errors when the field is modified
        if (errors[name]) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
        }
    };

    const validateForm = () => {
        const requiredFields = [
            "name",
            "street",
            "city",
            "country",
            "latitude",
            "longitude",
        ];
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!formData[field] || formData[field].toString().trim() === "") {
                newErrors[field] = `${field.replace(/_/g, " ")} is required.`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Form is valid if there are no errors
    };



    const handleSave = async () => {
        if (!validateForm()) {
            alert("Please fill in all required fields.");
            return;
        }

        try {
            const payload = {
                ...formData,
                established_date: formData.established_date || null,
                decommission_date: formData.decommission_date || null,
                document_ids: formData.document_ids || [],
            };

            if (selectedBuilding) {
                await axios.put(`/api/buildings/${selectedBuilding.id}`, payload);
            } else {
                await axios.post("/api/buildings", payload);
            }

            fetchBuildings();
            alert("Building saved successfully!");
            if (typeof onSave === "function") onSave();
        } catch (error) {
            console.error("Error saving building:", error);
            alert("Error saving building. Please try again.");
        }
    };

    const handleDelete = async () => {
        if (!selectedBuilding) return;
        if (window.confirm("Are you sure you want to delete this building?")) {
            try {
                await axios.delete(`/api/buildings/${selectedBuilding.id}`);
                fetchBuildings();
                setSelectedBuilding(null);
                setFormData({});
                alert("Building deleted successfully!");
                if (typeof onDelete === "function") onDelete();
            } catch (error) {
                console.error("Error deleting building:", error);
                alert("Error deleting building. Please try again.");
            }
        }
    };

    return (
        <div className="building-modal-overlay">
            <div className="building-modal-content">
                <button className="building-close-btn" onClick={onClose}>
                    &times;
                </button>
                <h2>{isAddingNew ? "Add New Building" : "Edit Building"}</h2>

                {/* Dropdown to select a building */}
                <div className="building-form-group">
                    <label>Select Building</label>
                    <Select
                        options={[
                            { value: "new", label: "Add New Building" }, // "Add New" option
                            ...buildings.map((building) => ({
                                value: building.id,
                                label: building.name,
                            })),
                        ]}
                        onChange={(selectedOption) =>
                            handleBuildingSelect(selectedOption ? selectedOption.value : null)
                        }
                        value={
                            isAddingNew
                                ? { value: "new", label: "Add New Building" }
                                : selectedBuilding
                                ? { value: selectedBuilding.id, label: selectedBuilding.name }
                                : null
                        }
                        isSearchable
                        isClearable
                        placeholder="Choose or Add New Building"
                    />
                </div>

                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <div className="building-form-container">
                        {/* Loop through all fields */}
                        {[
                            "name",
                            "primary_contact",
                            "street",
                            "phone_number",
                            "city",
                            "county",
                            "postcode",
                            "status",
                            "capacity",
                            // "architect_name",
                            "land_area",
                            "building_type",
                            "historical_status",
                            "primary_usage",
                            "ownership_status",
                            "maintenance_contact",
                            "country",
                            "latitude",
                            "longitude",
                            "description",
                            "established_date",
                            "decommission_date",
                            "builder", // New field
                            "consecrated_by", // New field
                            "architect", // New field
                        ].map((key) => (
                            <div className="building-form-group" key={key}>
                                <label>{key.replace(/_/g, " ")}</label>
                                {key === "historical_status" ? (
                                    <input
                                        type="checkbox"
                                        name={key}
                                        checked={formData[key] || false}
                                        onChange={handleInputChange}
                                        className={errors[key] ? "error" : ""}
                                    />
                                ) : key === "established_date" || key === "decommission_date" ? (
                                    <input
                                        type="date"
                                        name={key}
                                        value={formData[key] || ""}
                                        onChange={handleInputChange}
                                        className={errors[key] ? "error" : ""}
                                    />
                                ) : (
                                    <input
                                        type={
                                            key === "land_area" ||
                                            key === "capacity" ||
                                            key === "latitude" ||
                                            key === "longitude"
                                                ? "number"
                                                : "text"
                                        }
                                        step={
                                            key === "latitude" || key === "longitude"
                                                ? "0.000001"
                                                : undefined
                                        }
                                        name={key}
                                        value={formData[key] || ""}
                                        onChange={handleInputChange}
                                        className={errors[key] ? "error" : ""}
                                    />
                                )}
                                {errors[key] && <p className="error-message">{errors[key]}</p>}
                            </div>
                        ))}

                        {/* Document IDs */}
                        <div className="building-form-group">
                            <label>Document IDs (Comma-separated)</label>
                            <input
                                type="text"
                                name="document_ids"
                                value={(formData.document_ids || []).join(", ")}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        document_ids: e.target.value
                                            .split(",")
                                            .map((id) => id.trim()),
                                    })
                                }
                            />
                        </div>
                    </div>
                )}

                <div className="building-modal-actions">
                    {selectedBuilding && (
                        <button className="building-delete-btn" onClick={handleDelete}>
                            Delete
                        </button>
                    )}
                    <button className="building-save-btn" onClick={handleSave}>
                        Save
                    </button>
                    <button className="building-cancel-btn" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default BuildingDetailsModal;
