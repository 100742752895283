import React, { useState, useEffect } from "react";
import axios from "axios";
import "./OfficerHistoryModal.css";
import Select from "react-select";

function OfficerHistoryModal({ memberId, onClose }) {
    const [officers, setOfficers] = useState([]);
    const [membershipHistory, setMembershipHistory] = useState([]);
    const [roles, setRoles] = useState([]);
    const [ranks, setRanks] = useState([]);
    const [lodges, setLodges] = useState([]);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [editingId, setEditingId] = useState(null);
    const [membershipTypes, setMembershipTypes] = useState([]);
    const [membershipStatuses, setMembershipStatuses] = useState([]);

    const fetchMembershipTypes = async () => {
        try {
            const res = await axios.get("/api/crud2/membership-types");
            console.log("Membership Types:", res.data); // Debugging
            setMembershipTypes(res.data);
        } catch (error) {
            console.error("Error fetching membership types:", error);
        }
    };

    const fetchMembershipStatuses = async () => {
        try {
            const res = await axios.get("/api/crud2/membership-statuses");
            console.log("Membership Statuses:", res.data); // Debugging
            setMembershipStatuses(res.data);
        } catch (error) {
            console.error("Error fetching membership statuses:", error);
        }
    };
    useEffect(() => {
        if (memberId) {
            fetchOfficerHistory();
            fetchMembershipHistoryForMember();
            fetchRoles();
            fetchRanks();
            fetchLodges();
            // fetchMembershipTypes();
            // fetchMembershipStatuses();
        }
    }, [memberId]);

    const formatToSAST = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return "";
        const offsetInMs = 2 * 60 * 60 * 1000; // SAST is UTC+2
        const sastDate = new Date(date.getTime() + offsetInMs);
        return sastDate.toISOString().split("T")[0];
    };

    const fetchOfficerHistory = async () => {
        try {
            const res = await axios.get(`/api/members/${memberId}/officers`);
            setOfficers(res.data);
        } catch (error) {
            console.error("Error fetching officer history:", error);
            alert("Failed to fetch officer history.");
        }
    };

    const fetchMembershipHistoryForMember = async () => {
        try {
            // Fetch membership types and statuses before mapping history
            const [typesRes, statusesRes] = await Promise.all([axios.get("/api/crud2/membership-types"), axios.get("/api/crud2/membership-statuses")]);

            const membershipTypes = typesRes.data;
            const membershipStatuses = statusesRes.data;

            const res = await axios.get(`/api/resources/members/${memberId}/history`);
            const historyData = res.data.map((hist) => {
                const membershipType = membershipTypes.find((type) => type.id === hist.membership_type_id);
                const membershipStatus = membershipStatuses.find((status) => status.id === hist.membership_status_id);

                return {
                    ...hist,
                    membership_type: membershipType ? membershipType.name : "Unknown",
                    membership_status: membershipStatus ? membershipStatus.name : "Unknown",
                };
            });

            setMembershipHistory(historyData);
        } catch (error) {
            console.error("Error fetching membership history:", error);
            alert("Failed to fetch membership history.");
        }
    };

    const fetchRoles = async () => {
        try {
            const res = await axios.get("/api/crud/officers");
            setRoles(res.data);
        } catch (error) {
            console.error("Error fetching officer roles:", error);
            alert("Failed to fetch officer roles.");
        }
    };

    const fetchRanks = async () => {
        try {
            const res = await axios.get("/api/crud/ranks");
            setRanks(res.data);
        } catch (error) {
            console.error("Error fetching ranks:", error);
            alert("Failed to fetch ranks.");
        }
    };

    const fetchLodges = async () => {
        try {
            const res = await axios.get("/api/crud/lodges");
            setLodges(res.data);
        } catch (error) {
            console.error("Error fetching lodges:", error);
            alert("Failed to fetch lodges.");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target || {}; // Handle react-select
        setFormData({
            ...formData,
            [name]: value,
        });

        if (errors[name]) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
        }
    };

    const validateForm = () => {
        const requiredFields = ["membership_history_id", "lodge_id", "officer_role_id", "rank_id", "start_date"];
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = `${field.replace(/_/g, " ")} is required.`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            alert("Please fill in all required fields.");
            return;
        }

        const url = editingId ? `/api/members/${memberId}/officers/${editingId}` : `/api/members/${memberId}/officers`;
        const method = editingId ? "put" : "post";

        try {
            await axios[method](url, formData);
            fetchOfficerHistory();
            setFormData({});
            setEditingId(null);
            alert("Officer history saved successfully!");
        } catch (error) {
            console.error("Error saving officer history:", error.response?.data || error.message);
            alert("Failed to save officer history.");
        }
    };

    const handleEdit = (officer) => {
        const selectedMembershipHistory = membershipHistory.find(
            (hist) => hist.id === officer.membership_history_id
        );
        setEditingId(officer.id);
        setFormData({
            membership_history_id: selectedMembershipHistory ? selectedMembershipHistory.id : "",
            lodge_id: officer.lodge_id,
            officer_role_id: officer.officer_role_id,
            rank_id: officer.rank_id,
            start_date: officer.start_date ? formatToSAST(officer.start_date) : "",
            end_date: officer.end_date ? formatToSAST(officer.end_date) : "",
            comments: officer.comments || "",
        });
    };

    const handleDelete = async (officerId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this officer history?");
        if (!confirmDelete) return;

        try {
            await axios.delete(`/api/members/${memberId}/officers/${officerId}`);
            fetchOfficerHistory();
            alert("Officer history deleted successfully!");
        } catch (error) {
            console.error("Error deleting officer history:", error);
            alert("Failed to delete officer history.");
        }
    };

    return (
        <div className="oh-modal-overlay">
            <div className="oh-modal-content">
                <div className="oh-modal-header">
                    <h2>Officer History</h2>
                    <button className="oh-modal-close-btn" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className="oh-modal-body">
                    <ul className="oh-officer-history-list">
                        {officers.map((officer) => (
                            <li key={officer.id} className="oh-officer-history-item">
                                <strong>{lodges.find((lodge) => lodge.id === officer.lodge_id)?.name || "Unknown Lodge"}</strong>
                                <p>
                                    {officer.officer_role} ({officer.rank_name}) <br />
                                    {formatToSAST(officer.start_date)} to {officer.end_date ? formatToSAST(officer.end_date) : "Present"}
                                </p>
                                <div className="oh-officer-history-buttons">
                                    <button className="oh-officer-history-edit-btn" onClick={() => handleEdit(officer)}>
                                        Edit
                                    </button>
                                    <button className="oh-officer-history-delete-btn" onClick={() => handleDelete(officer.id)}>
                                        Delete
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <form className="oh-officer-history-form">
                        <label>Membership History</label>
                        <Select
                            name="membership_history_id"
                            options={membershipHistory.map((hist) => ({
                                value: hist.id,
                                label: `Lodge: ${
                                    lodges.find((l) => l.id === hist.lodge_id)?.name || "Unknown"
                                } | Type: ${hist.membership_type} | Status: ${hist.membership_status} (${formatToSAST(
                                    hist.start_date
                                )} - ${hist.end_date ? formatToSAST(hist.end_date) : "Present"})`,
                            }))}
                            onChange={(selectedOption) =>
                                handleInputChange({
                                    target: { name: "membership_history_id", value: selectedOption ? selectedOption.value : "" },
                                })
                            }
                            value={
                                formData.membership_history_id
                                    ? membershipHistory
                                        .filter((hist) => hist.id === formData.membership_history_id)
                                        .map((hist) => ({
                                            value: hist.id,
                                            label: `Lodge: ${
                                                lodges.find((l) => l.id === hist.lodge_id)?.name || "Unknown"
                                            } | Type: ${hist.membership_type} | Status: ${hist.membership_status} (${formatToSAST(
                                                hist.start_date
                                            )} - ${hist.end_date ? formatToSAST(hist.end_date) : "Present"})`,
                                        }))[0]
                                    : null
                            }
                            isClearable
                            placeholder="Select Membership History"
                        />
                        {errors.membership_history_id && (
                            <p className="oh-error-message">{errors.membership_history_id}</p>
                        )}
                        {/*{errors.membership_history_id && <p className="oh-error-message">{errors.membership_history_id}</p>}*/}

                        <label>Lodge</label>
                        <Select
                            options={lodges.map((lodge) => ({
                                value: lodge.id,
                                label: lodge.name,
                            }))}
                            onChange={(selectedOption) => handleInputChange({ target: { name: "lodge_id", value: selectedOption ? selectedOption.value : "" } })}
                            value={lodges.find((lodge) => lodge.id === formData.lodge_id) ? { value: formData.lodge_id, label: lodges.find((lodge) => lodge.id === formData.lodge_id).name } : null}
                            isSearchable
                            isClearable
                            placeholder="Select Lodge"
                            className={`oh-error ${errors.lodge_id ? "oh-error-field" : ""}`}
                        />
                        {errors.lodge_id && <p className="oh-error-message">{errors.lodge_id}</p>}

                        <label>Officer Role</label>
                        <Select
                            options={roles.map((role) => ({
                                value: role.id,
                                label: role.role_name,
                            }))}
                            onChange={(selectedOption) => handleInputChange({ target: { name: "officer_role_id", value: selectedOption ? selectedOption.value : "" } })}
                            value={roles.find((role) => role.id === formData.officer_role_id) ? { value: formData.officer_role_id, label: roles.find((role) => role.id === formData.officer_role_id).role_name } : null}
                            isSearchable
                            isClearable
                            placeholder="Select Role"
                            className={`oh-error ${errors.officer_role_id ? "oh-error-field" : ""}`}
                        />
                        {errors.officer_role_id && <p className="oh-error-message">{errors.officer_role_id}</p>}

                        <label>Rank</label>
                        <Select
                            options={ranks.map((rank) => ({
                                value: rank.id,
                                label: rank.rank_name,
                            }))}
                            onChange={(selectedOption) => handleInputChange({ target: { name: "rank_id", value: selectedOption ? selectedOption.value : "" } })}
                            value={ranks.find((rank) => rank.id === formData.rank_id) ? { value: formData.rank_id, label: ranks.find((rank) => rank.id === formData.rank_id).rank_name } : null}
                            isSearchable
                            isClearable
                            placeholder="Select Rank"
                            className={`oh-error ${errors.rank_id ? "oh-error-field" : ""}`}
                        />
                        {errors.rank_id && <p className="oh-error-message">{errors.rank_id}</p>}

                        <label>Start Date</label>
                        <input type="date" name="start_date" value={formData.start_date || ""} onChange={handleInputChange} className={errors.start_date ? "oh-error" : ""} />
                        {errors.start_date && <p className="oh-error-message">{errors.start_date}</p>}

                        <label>End Date</label>
                        <input type="date" name="end_date" value={formData.end_date || ""} onChange={handleInputChange} />

                        <label>Comments</label>
                        <textarea name="comments" value={formData.comments || ""} onChange={handleInputChange} />

                        <button type="button" className="oh-officer-history-save-btn" onClick={handleSave}>
                            {editingId ? "Update" : "Add"}
                        </button>
                    </form>
                </div>
                <div className="oh-modal-footer">
                    <button className="oh-modal-close-btn" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default OfficerHistoryModal;
