import React, { useEffect, useState } from "react";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./MemberProfileModal.css";

function MemberProfileModal({ memberId, onClose }) {
    const [profileData, setProfileData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (memberId) fetchProfileData();
    }, [memberId]);

    const fetchProfileData = async () => {
        try {
            const res = await axios.get(`/api/resources/members/${memberId}/profile`);
            setProfileData(res.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching profile data:", error);
            alert("Failed to fetch profile data.");
            setLoading(false);
        }
    };

    // Utility function to format date to SAST (YYYY-MM-DD)
    const formatToSAST = (dateString) => {
        if (!dateString) return "N/A";
        const date = new Date(dateString);
        const offsetInMs = 2 * 60 * 60 * 1000; // SAST is UTC+2
        const sastDate = new Date(date.getTime() + offsetInMs);
        return sastDate.toISOString().split("T")[0];
    };

    const handleExportPDF = () => {
        const doc = new jsPDF();

        // Add header
        doc.setFontSize(18);
        doc.text(`${profileData.first_name} ${profileData.last_name}`, 14, 20);
        doc.setFontSize(12);
        doc.text("Member Profile Report", 14, 30);

        // Personal Details
        let currentY = 40;
        doc.setFontSize(14);
        doc.text("Personal Details", 14, currentY);
        doc.setFontSize(10);
        currentY += 5;
        doc.text(`Date of Birth: ${formatToSAST(profileData.date_of_birth)}`, 14, currentY);
        currentY += 5;
        doc.text(`Date of Death: ${profileData.date_of_death ? formatToSAST(profileData.date_of_death) : "N/A"}`, 14, currentY);
        currentY += 5;
        doc.text(`Phone Number: ${profileData.phone_number || "N/A"}`, 14, currentY);
        currentY += 5;
        doc.text(`Email Address: ${profileData.email_address || "N/A"}`, 14, currentY);
        currentY += 5;
        doc.text(`Address: ${profileData.address || "N/A"}`, 14, currentY);
        currentY += 5;
        doc.text(`City: ${profileData.city || "N/A"}`, 14, currentY);
        currentY += 5;
        doc.text(`State: ${profileData.state || "N/A"}`, 14, currentY);
        currentY += 5;
        doc.text(`Country: ${profileData.country_name || "N/A"}`, 14, currentY);

        // Membership History
        currentY += 10;
        doc.setFontSize(14);
        doc.text("Membership History", 14, currentY);
        doc.autoTable({
            startY: currentY + 5,
            head: [["Membership", "Membership Type", "Status", "Start Date", "End Date"]],
            body: profileData.membership_history.map((history) => [
                history.lodge_name,
                history.membership_type,
                history.membership_status,
                formatToSAST(history.start_date),
                history.end_date ? formatToSAST(history.end_date) : "Present",
            ]),
        });



        // Roles and Ranks
        currentY = doc.lastAutoTable.finalY + 10;
        doc.setFontSize(14);
        doc.text("Roles and Ranks", 14, currentY);
        doc.autoTable({
            startY: currentY + 5,
            head: [["Membership", "Role", "Rank", "Start Date", "End Date"]],
            body: profileData.roles.map((role) => [
                role.lodge_name,
                role.role_name,
                role.rank_name || "N/A",
                formatToSAST(role.start_date),
                role.end_date ? formatToSAST(role.end_date) : "Present",
            ]),
        });

        // Degrees
        currentY = doc.lastAutoTable.finalY + 10;
        doc.setFontSize(14);
        doc.text("Degrees", 14, currentY);
        doc.autoTable({
            startY: currentY + 5,
            head: [["Degree Name", "Date Obtained"]],
            body: profileData.degrees.map((degree) => [
                degree.degree_name,
                formatToSAST(degree.date_obtained),
            ]),
        });

        // Meetings
        currentY = doc.lastAutoTable.finalY + 10;
        doc.setFontSize(14);
        doc.text("Meetings Attended", 14, currentY);
        currentY += 5;
        doc.setFontSize(10);
        doc.text(`Total Visits: ${profileData.total_visits}`, 14, currentY);
        currentY += 5;
        doc.text(`Total Meetings: ${profileData.total_meetings}`, 14, currentY);
        doc.autoTable({
            startY: currentY + 5,
            head: [["Date", "Membership", "Event Type", "Attendance Type"]],
            body: profileData.meetings.map((meeting) => [
                formatToSAST(meeting.meeting_date),
                meeting.lodge_name,
                meeting.eventtype_name,
                meeting.attendance_type,
            ]),
        });

        // Save the PDF
        doc.save(`${profileData.first_name}_${profileData.last_name}_Profile.pdf`);
    };

    if (loading) return <div>Loading profile...</div>;

    if (!profileData) return <div>No profile data available.</div>;

    return (
        <div className="profile-modal-overlay">
            <div className="profile-modal-content">
                <div className="profile-modal-header">
                    <h2>{`${profileData.first_name} ${profileData.last_name}`}</h2>
                    <button className="profile-modal-close-btn" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className="profile-modal-body">
                    <h3>Personal Details</h3>
                    <p><strong>Date of Birth:</strong> {formatToSAST(profileData.date_of_birth)}</p>
                    <p><strong>Date of Death:</strong> {profileData.date_of_death ? formatToSAST(profileData.date_of_death) : "N/A"}</p>
                    <p><strong>Phone Number:</strong> {profileData.phone_number || "N/A"}</p>
                    <p><strong>Email Address:</strong> {profileData.email_address || "N/A"}</p>
                    <p><strong>Address:</strong> {profileData.address || "N/A"}</p>
                    <p><strong>City:</strong> {profileData.city || "N/A"}</p>
                    <p><strong>State:</strong> {profileData.state || "N/A"}</p>
                    <p><strong>Country:</strong> {profileData.country_name || "N/A"}</p>

                    <h3>Membership History</h3>
                    <ul>
                        {profileData.membership_history.map((history) => (
                            <li key={history.id}>
                                <strong>{history.lodge_name}</strong>: {history.membership_type}, {history.membership_status}
                                <br />
                                {formatToSAST(history.start_date)} - {history.end_date ? formatToSAST(history.end_date) : "Present"}
                            </li>
                        ))}
                    </ul>



                    <h3>Roles and Ranks</h3>
                    <ul>
                        {profileData.roles.map((role) => (
                            <li key={role.id}>
                                <strong>{role.lodge_name}</strong>: {role.role_name} ({role.rank_name || "N/A"})
                                <br />
                                {formatToSAST(role.start_date)} - {role.end_date ? formatToSAST(role.end_date) : "Present"}
                            </li>
                        ))}
                    </ul>

                    <h3>Degrees</h3>
                    <ul>
                        {profileData.degrees.map((degree) => (
                            <li key={degree.id}>
                                {degree.degree_name}, obtained on {formatToSAST(degree.date_obtained)}
                            </li>
                        ))}
                    </ul>

                    <h3>Meetings Attended</h3>
                    <p><strong>Total Visits:</strong> {profileData.total_visits}</p>
                    <p><strong>Total Meetings:</strong> {profileData.total_meetings}</p>
                    <ul>
                        {profileData.meetings.map((meeting) => (
                            <li key={meeting.id}>
                                <strong>{meeting.lodge_name}</strong>: {meeting.eventtype_name}
                                <br />
                                Date: {formatToSAST(meeting.meeting_date)}, Attendance: {meeting.attendance_type}
                            </li>
                        ))}
                    </ul>

                </div>
                <div className="profile-modal-footer">
                    <button className="profile-modal-export-btn" onClick={handleExportPDF}>
                        Export as PDF
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MemberProfileModal;
