import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Chart from "chart.js/auto";
import "./StatsModal.css";
import ReactECharts from "echarts-for-react"; // ECharts wrapper for React
import Select from "react-select"; // Import react-select

function StatsModal({ statsData: initialStatsData, onClose }) {
    const [statsData, setStatsData] = useState(initialStatsData);
    const [lodges, setLodges] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [selectedLodge, setSelectedLodge] = useState("");
    const [selectedEventType, setSelectedEventType] = useState("");
    const [expandedAttendees, setExpandedAttendees] = useState({});
    const [expandedMeetings, setExpandedMeetings] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [attendeeModal, setAttendeeModal] = useState({ isOpen: false, attendees: [], meetingId: null });
    const [sankeyChartSize, setSankeyChartSize] = useState(500); // Default chart size
    const [chartSize, setChartSize] = useState({ width: 800, height: 500 }); // Default Sankey chart size
    const [sankeyModal, setSankeyModal] = useState(false); // Control Sankey modal visibility
    const [sankeyData, setSankeyData] = useState({ nodes: [], links: [] }); // Sankey data

    const chartRef = useRef(null);
    let chartInstance = useRef(null);

    useEffect(() => {
        // Set default date range: last year to today
        const today = new Date();
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 20);

        const formatDate = (d) => d.toISOString().split("T")[0];

        setStartDate(formatDate(oneYearAgo));
        setEndDate(formatDate(today));
    }, []);

    useEffect(() => {
        fetchLodges();
        fetchEventTypesUsedInMeetings();
    }, []);

    useEffect(() => {
        if (startDate && endDate) {
            fetchStats();
        }
    }, [selectedLodge, selectedEventType, startDate, endDate]);

    // const fetchAttendeesForMeeting = async (meetingId) => {
    //     try {
    //         const res = await axios.get(`/api/meetings/${meetingId}/attendees-detailed`);
    //         setExpandedAttendees((prev) => ({
    //             ...prev,
    //             [meetingId]: res.data,
    //         }));
    //     } catch (error) {
    //         console.error("Error fetching attendees for meeting:", error);
    //     }
    // };
    const fetchAttendeesForMeeting = async (meetingId) => {
        try {
            const res = await axios.get(`/api/meetings/${meetingId}/attendees-detailed`);
            setAttendeeModal({ isOpen: true, attendees: res.data, meetingId });
        } catch (error) {
            console.error("Error fetching attendees for meeting:", error);
        }
    };
    const closeModal = () => {
        setAttendeeModal({ isOpen: false, attendees: [], meetingId: null });
    };

    const increaseSankeySize = () => {
        setSankeyChartSize((prevSize) => Math.min(prevSize + 50, 1000)); // Max size: 1000px
    };

    const decreaseSankeySize = () => {
        setSankeyChartSize((prevSize) => Math.max(prevSize - 50, 300)); // Min size: 300px
    };

    const toggleMeetingExpand = (meetingId) => {
        if (expandedMeetings.includes(meetingId)) {
            setExpandedMeetings((prev) => prev.filter((id) => id !== meetingId)); // Collapse
        } else {
            fetchAttendeesForMeeting(meetingId); // Fetch data for the meeting if not already fetched
            setExpandedMeetings((prev) => [...prev, meetingId]); // Expand
        }
    };

    const fetchLodges = async () => {
        try {
            const res = await axios.get("/api/crud/lodges");
            setLodges(
                res.data.map((lodge) => ({
                    value: lodge.id, // Unique ID
                    label: lodge.name, // Display name
                }))
            );
        } catch (error) {
            console.error("Error fetching lodges:", error);
        }
    };

    const fetchEventTypesUsedInMeetings = async () => {
        try {
            const res = await axios.get("/api/event-types/used-in-meetings");
            setEventTypes(
                res.data.map((eventType) => ({
                    value: eventType.id, // Unique ID
                    label: eventType.description, // Display description
                }))
            );
        } catch (error) {
            console.error("Error fetching event types:", error);
        }
    };

    const fetchStats = async () => {
        try {
            const params = {};
            if (selectedLodge) params.lodge_id = selectedLodge; // Use lodge_id from react-select
            if (selectedEventType) params.eventtype_id = selectedEventType; // Use eventtype_id from react-select
            if (startDate) params.from = startDate;
            if (endDate) params.to = endDate;

            const res = await axios.get("/api/stats/meetings", { params });
            setStatsData(res.data);
        } catch (error) {
            console.error("Error fetching filtered stats:", error);
        }
    };

    const handleLodgeChange = (selectedOption) => {
        setSelectedLodge(selectedOption?.value || ""); // Extract the value from the selected option
    };

    const handleEventTypeChange = (selectedOption) => {
        setSelectedEventType(selectedOption?.value || ""); // Extract the value from the selected option
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const {
        total_meetings,
        average_attendance,
        monthly_breakdown = [],
        top_visitor_lodges = [],
        lodge_breakdown = [],
        eventtype_breakdown = [],
        meetings_visitor_lodges = []
    } = statsData;


    const prepareSankeyModal = () => {
        if (!statsData || !statsData.meetings_visitor_lodges) return;

        const nodes = [];
        const links = [];

        statsData.meetings_visitor_lodges.forEach((item) => {
            if (item.visitor_lodges) {
                item.visitor_lodges.split(", ").forEach((visitorLodge) => {
                    // Add nodes for visitor and meeting lodges
                    if (!nodes.some((node) => node.name === visitorLodge)) {
                        nodes.push({ name: visitorLodge });
                    }
                    if (!nodes.some((node) => node.name === item.meeting_lodge)) {
                        nodes.push({ name: item.meeting_lodge });
                    }

                    // Add link between visitor and meeting lodges
                    links.push({
                        source: visitorLodge,
                        target: item.meeting_lodge,
                        value: 1, // Adjust based on real visitor counts if available
                    });
                });
            }
        });

        setSankeyData({ nodes, links });
        setSankeyModal(true); // Open the modal
    };

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }
        if (monthly_breakdown.length > 0) {
            const ctx = chartRef.current.getContext("2d");
            chartInstance.current = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: monthly_breakdown.map(m => m.month),
                    datasets: [
                        {
                            label: 'Member Attendance',
                            data: monthly_breakdown.map(m => m.member_attendance || 0),
                            backgroundColor: 'rgba(75, 192, 192, 0.7)'
                        },
                        {
                            label: 'Visitor Attendance',
                            data: monthly_breakdown.map(m => m.visitor_attendance || 0),
                            backgroundColor: 'rgba(255, 159, 64, 0.7)'
                        }
                    ]
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            stacked: true,
                            title: { display: true, text: 'Month' }
                        },
                        y: {
                            stacked: true,
                            title: { display: true, text: 'Attendance Count' }
                        }
                    },
                    plugins: {
                        tooltip: { mode: 'index', intersect: false },
                        legend: { position: 'top' }
                    }
                }
            });
        }
    }, [monthly_breakdown]);

    return (
        <div className="st-modal-overlay">
            <div className="st-modal-content">
                <div className="st-modal-header">
                    <h2>Meeting Statistics</h2>
                    <button className="st-modal-close-btn" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className="st-modal-body">
                    <div className="st-filters">
                        <div className="st-filter-row">
                            <div className="st-filter-group">
                                <label htmlFor="start-date">Start Date:</label>
                                <input type="date" id="start-date" value={startDate} onChange={handleStartDateChange} />
                            </div>
                            <div className="st-filter-group">
                                <label htmlFor="end-date">End Date:</label>
                                <input type="date" id="end-date" value={endDate} onChange={handleEndDateChange} />
                            </div>
                        </div>
                        <div className="st-filter-row">
                            <div className="st-filter-group">
                                <label htmlFor="lodge-filter">Lodge Filter:</label>
                                <Select
                                    id="lodge-filter"
                                    options={[{ value: "", label: "All Lodges" }, ...lodges]} // Add "All Lodges" option
                                    value={lodges.find((lodge) => lodge.value === selectedLodge) || { value: "", label: "All Lodges" }}
                                    onChange={(selectedOption) => setSelectedLodge(selectedOption?.value || "")}
                                    placeholder="Filter by Lodge"
                                    isClearable
                                    isSearchable
                                />
                            </div>
                            <div className="st-filter-group">
                                <label htmlFor="eventtype-filter">Event Type Filter:</label>
                                <Select
                                    id="eventtype-filter"
                                    options={[{ value: "", label: "All Types" }, ...eventTypes]} // Add "All Types" option
                                    value={eventTypes.find((event) => event.value === selectedEventType) || { value: "", label: "All Types" }}
                                    onChange={(selectedOption) => setSelectedEventType(selectedOption?.value || "")}
                                    placeholder="Filter by Event Type"
                                    isClearable
                                    isSearchable
                                />
                            </div>
                            <button onClick={prepareSankeyModal} className="visitor-sankey-btn">
                                Visitor Sankey
                            </button>
                        </div>
                    </div>

                    <div className="st-section">
                        <h3>Overall Stats</h3>
                        <div className="st-metrics">
                            <div className="st-metric">
                                <span className="st-metric-value">{total_meetings}</span>
                                <span className="st-metric-label">Total Meetings</span>
                            </div>
                            <div className="st-metric">
                                <span className="st-metric-value">{average_attendance}</span>
                                <span className="st-metric-label">Avg Attendance</span>
                            </div>
                        </div>
                    </div>

                    <div className="st-section">
                        <h3>Monthly Breakdown (Members vs Visitors)</h3>
                        <div className="st-chart-container">
                            <canvas ref={chartRef}></canvas>
                        </div>
                        <table className="st-stats-table">
                            <thead>
                            <tr>
                                <th>Month</th>
                                <th>Meetings</th>
                                <th>Member Attendance</th>
                                <th>Visitor Attendance</th>
                                <th>Avg Attendance</th>
                            </tr>
                            </thead>
                            <tbody>
                            {monthly_breakdown.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.month}</td>
                                    <td>{item.meetings}</td>
                                    <td>{item.member_attendance || 0}</td>
                                    <td>{item.visitor_attendance || 0}</td>
                                    <td>{item.avg_attendance}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="st-section">
                        <h3>Top Visitor Lodges</h3>
                        <table className="st-stats-table">
                            <thead>
                            <tr>
                                <th>Lodge Name</th>
                                <th>Visitor Count</th>
                            </tr>
                            </thead>
                            <tbody>
                            {top_visitor_lodges.map((lod, index) => (
                                <tr key={index}>
                                    <td>{lod.name}</td>
                                    <td>{lod.visitor_count}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="st-section">
                        <h3>Lodge Breakdown</h3>
                        <table className="st-stats-table">
                            <thead>
                            <tr>
                                <th>Lodge Name</th>
                                <th>Meetings</th>
                                <th>Avg Attendance</th>
                            </tr>
                            </thead>
                            <tbody>
                            {lodge_breakdown.map((lod, index) => (
                                <tr key={index}>
                                    <td>{lod.name}</td>
                                    <td>{lod.meetings}</td>
                                    <td>{lod.avg_attendance}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>

                    <div className="st-section">
                        <h3>Event Type Breakdown</h3>
                        <table className="st-stats-table">
                            <thead>
                            <tr>
                                <th>Event Type</th>
                                <th>Meetings</th>
                                <th>Avg Attendance</th>
                            </tr>
                            </thead>
                            <tbody>
                            {eventtype_breakdown.map((evt, index) => (
                                <tr key={index}>
                                    <td>{evt.description}</td>
                                    <td>{evt.meetings}</td>
                                    <td>{evt.avg_attendance}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>


                    {meetings_visitor_lodges.map((row,index) => (
                        <tr key={index}>
                            <td>{row.meeting_date}</td>
                            <td>{row.meeting_lodge}</td>
                            <td>{row.event_type}</td>
                            <td>{row.visitor_lodges}</td>
                            {/*<td>{row.visitor_count}</td>*/}
                            <td>
                                <button onClick={() => fetchAttendeesForMeeting(row.meeting_id)}>
                                    Show Details
                                </button>
                            </td>
                        </tr>
                    ))}
                    {/*</tbody>*/}
                </div>

                {attendeeModal.isOpen && (
                    <div className="attendee-modal-overlay">
                        <div className="attendee-modal-content">
                            <div className="attendee-modal-header">
                                <h2>Attendees for Meeting</h2>
                                <button onClick={closeModal}>&times;</button>
                            </div>
                            <div className="attendee-modal-body">
                                <table className="st-inner-table">
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Visitor</th>
                                        <th>Lodge</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {attendeeModal.attendees.map((attendee) => (
                                        <tr key={attendee.attendee_id}>
                                            <td>{attendee.member_name || "Unknown"}</td>
                                            <td>{attendee.visitor ? "Yes" : "No"}</td>
                                            <td>{attendee.lodge_name || "Unknown Lodge"}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="attendee-modal-footer">
                                <button onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                )}

                {sankeyModal && (
                    <div className="sankey-modal-overlay">
                        <div className="sankey-modal-content">
                            <div className="sankey-modal-header">
                                <h2>Visitor Sankey Diagram</h2>
                                <button onClick={() => setSankeyModal(false)}>&times;</button>
                                <button
                                    className="sankey-save-button"
                                    onClick={() => {
                                        const chartInstance = chartRef.current.getEchartsInstance();
                                        const imgBase64 = chartInstance.getDataURL({
                                            type: "png",
                                            backgroundColor: "#ffffff", // Ensure a white background
                                        });

                                        const link = document.createElement("a");
                                        link.href = imgBase64;
                                        link.download = "sankey_chart.png";
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }}
                                >
                                    Save Chart
                                </button>
                            </div>
                            <div className="sankey-modal-body">
                                <div className="sankey-controls">
                                    <button
                                        onClick={() =>
                                            setChartSize((prevSize) => ({
                                                width: prevSize.width - 200,
                                                height: prevSize.height - 150,
                                            }))
                                        }
                                    >
                                        Reduce Size
                                    </button>
                                    <button
                                        onClick={() =>
                                            setChartSize((prevSize) => ({
                                                width: prevSize.width + 200,
                                                height: prevSize.height + 150,
                                            }))
                                        }
                                    >
                                        Increase Size
                                    </button>
                                </div>
                                <ReactECharts
                                    ref={chartRef}
                                    option={{
                                        tooltip: { trigger: "item" },
                                        series: [
                                            {
                                                type: "sankey",
                                                data: sankeyData.nodes,
                                                links: sankeyData.links,
                                                emphasis: { focus: "adjacency" },
                                                lineStyle: { color: "gradient", curveness: 0.5 },
                                            },
                                        ],
                                    }}
                                    style={{ width: `${chartSize.width}px`, height: `${chartSize.height}px` }}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <div className="st-modal-footer">
                    <button className="st-modal-close-btn" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
}

export default StatsModal;
