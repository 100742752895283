import React, { useState, useEffect } from "react";
import axios from "axios";
import "./MeetingAttendeesModal.css";

function MeetingAttendeesModal({ meetingId, onClose }) {
    const [attendees, setAttendees] = useState([]);
    const [members, setMembers] = useState([]);
    const [lodges, setLodges] = useState([]);
    const [formData, setFormData] = useState({ member_id: "", visitor: false, visitor_lodge_id: "", visitor_name: "" });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchAttendees();
        fetchMembers();
        fetchLodges();
    }, [meetingId]);

    const fetchAttendees = async () => {
        try {
            const res = await axios.get(`/api/meetings/${meetingId}/attendees`);
            setAttendees(res.data);
        } catch (error) {
            console.error("Error fetching attendees:", error);
        }
    };

    const fetchMembers = async () => {
        try {
            const res = await axios.get("/api/resources/members");
            setMembers(res.data);
        } catch (error) {
            console.error("Error fetching members:", error);
        }
    };

    const fetchLodges = async () => {
        try {
            const res = await axios.get("/api/crud/lodges");
            setLodges(res.data);
        } catch (error) {
            console.error("Error fetching lodges:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (name === "visitor") {
            setFormData((prev) => ({
                ...prev,
                visitor: checked,
                visitor_lodge_id: "",
                visitor_name: checked ? `${members.find((m) => m.id === prev.member_id)?.first_name || ""} ${members.find((m) => m.id === prev.member_id)?.last_name || ""}` : "",
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: type === "checkbox" ? checked : value,
            }));
        }

        if (errors[name]) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
        }
    };

    const validateAttendee = () => {
        if (!formData.visitor && !formData.member_id) {
            alert("Please select a member.");
            return false;
        }
        if (formData.visitor && !formData.visitor_lodge_id) {
            alert("Please select a lodge for the visitor.");
            return false;
        }
        return true;
    };

    const handleSave = async () => {
        if (!validateAttendee()) {
            return;
        }

        try {
            const payload = {
                member_id: formData.member_id || null,
                visitor: formData.visitor,
                visitor_lodge_id: formData.visitor ? formData.visitor_lodge_id : null,
                visitor_name: formData.visitor ? `${members.find((m) => m.id === formData.member_id)?.first_name || ""} ${members.find((m) => m.id === formData.member_id)?.last_name || ""}` : null,
            };

            await axios.post(`/api/meetings/${meetingId}/attendees`, payload);
            fetchAttendees();
            setFormData({ member_id: "", visitor: false, visitor_lodge_id: "", visitor_name: "" });
        } catch (error) {
            console.error("Error adding attendee:", error);
            alert("Failed to add attendee.");
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to remove this attendee?")) {
            try {
                await axios.delete(`/api/meetings/${meetingId}/attendees/${id}`);
                fetchAttendees();
            } catch (error) {
                console.error("Error removing attendee:", error);
                alert("Failed to remove attendee.");
            }
        }
    };

    return (
        <div className="ma-modal-overlay">
            <div className="ma-modal-content">
                <div className="ma-modal-header">
                    <h2>Meeting Attendees</h2>
                    <button className="ma-modal-close-btn" onClick={onClose}>
                        &times;
                    </button>
                </div>
                <div className="ma-modal-body">
                    <ul className="ma-attendee-list">
                        {attendees.map((att) => {
                            const member = members.find((m) => m.id === att.member_id);
                            let attendeeName = "Visitor";
                            if (!att.visitor && member) {
                                attendeeName = `${member.first_name} ${member.last_name}`;
                            } else if (att.visitor && member) {
                                const lodge = lodges.find((l) => l.id === att.visitor_lodge_id);
                                attendeeName = `${member.first_name} ${member.last_name} (Visitor, ${lodge ? lodge.name : "Unknown Lodge"})`;
                            } else if (att.visitor) {
                                const lodge = lodges.find((l) => l.id === att.visitor_lodge_id);
                                attendeeName = `${att.visitor_name || "Unknown"} (Visitor, ${lodge ? lodge.name : "Unknown Lodge"})`;
                            }
                            return (
                                <li key={att.id} className="ma-attendee-item">
                                    {attendeeName}
                                    <button className="ma-attendee-delete-btn" onClick={() => handleDelete(att.id)}>
                                        Remove
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="ma-attendee-form">
                        <h3>Add Attendee</h3>
                        <label>Member:</label>
                        <select name="member_id" value={formData.member_id} onChange={handleInputChange} disabled={formData.visitor}>
                            <option value="">Select Member</option>
                            {members.map((m) => (
                                <option key={m.id} value={m.id}>
                                    {m.first_name} {m.last_name}
                                </option>
                            ))}
                        </select>

                        <label>
                            <input type="checkbox" name="visitor" checked={formData.visitor} onChange={handleInputChange} />
                            Visitor
                        </label>

                        {formData.visitor && (
                            <>
                                <label>Visitor's Lodge</label>
                                <select name="visitor_lodge_id" value={formData.visitor_lodge_id} onChange={handleInputChange}>
                                    <option value="">Select Lodge</option>
                                    {lodges.map((l) => (
                                        <option key={l.id} value={l.id}>
                                            {l.name}
                                        </option>
                                    ))}
                                </select>
                            </>
                        )}

                        <button className="ma-attendee-save-btn" onClick={handleSave}>
                            Add Attendee
                        </button>
                    </div>
                </div>
                <div className="ma-modal-footer">
                    <button className="ma-modal-close-btn" onClick={onClose}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
}

export default MeetingAttendeesModal;
