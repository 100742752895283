import React, { useState, useEffect } from "react";
import axios from "axios";
import "./DocumentManagement.css"; // Import your CSS file here
import { logInteraction } from "../utils/logInteraction"; // Import the logging function
import Select from "react-select"; // Import react-select

function DocumentManagement({ user }) {
    useEffect(() => {
        logInteraction("page_view", { viewport: { width: window.innerWidth, height: window.innerHeight } }, user);
    }, [user]);

    const [file, setFile] = useState(null);
    const [dropdowns, setDropdowns] = useState({
        scopes: [],
        constitutions: [],
        countries: [],
        orderCodes: [],
        lodges: [],
        eventTypes: [],
        documentTypes: [],
        fileTypes: [],
        roles: [], // Add roles dropdown
    });
    const [formData, setFormData] = useState({
        scope: "LOD",
        constitution: "", // Should be initialized to a default if possible
        country: "", // Should be initialized to a default if possible
        orderCode: "",
        lodge: "",
        eventType: "", // Should be initialized to a default if possible
        documentType: "", // Should be initialized to a default if possible
        fileType: "", // Should be initialized to a default if possible
        date: "",
        version: "1",
        addendum: "",
        description: "",
        author_initials: "",
        doi: "",
        title: "",
        roles: [], // Array to hold selected roles
    });

    const [errors, setErrors] = useState({});

    // Fetch dropdown data
    // useEffect(() => {
    //     const fetchDropdownData = async () => {
    //         try {
    //             const [scopes, constitutions, countries, orderCodes, lodges, eventTypes, documentTypes, fileTypes, roles] = await Promise.all([axios.get("/api/scopes"), axios.get("/api/constitutions"), axios.get("/api/countries"), axios.get("/api/order-codes"), axios.get("/api/lodges"), axios.get("/api/event-types"), axios.get("/api/document-types"), axios.get("/api/file-types"), axios.get("/api/roles")]);
    //
    //             setDropdowns({
    //                 scopes: scopes.data.map((scope) => ({ value: scope.code, label: scope.description })),
    //                 constitutions: constitutions.data.map((constitution) => ({ value: constitution.code, label: constitution.description })),
    //                 countries: countries.data.map((country) => ({ value: country.code, label: country.name })),
    //                 orderCodes: orderCodes.data.map((orderCode) => ({ value: orderCode.code, label: orderCode.description })),
    //                 lodges: lodges.data.map((lodge) => ({ value: lodge.code, label: lodge.name })),
    //                 eventTypes: eventTypes.data.map((eventType) => ({ value: eventType.code, label: eventType.description })),
    //                 documentTypes: documentTypes.data.map((docType) => ({ value: docType.code, label: docType.description })),
    //                 fileTypes: fileTypes.data.map((fileType) => ({ value: fileType.code, label: fileType.description })),
    //                 roles: roles.data.map((role) => ({ value: role.id, label: role.name })),
    //             });
    //         } catch (error) {
    //             console.error("Error fetching dropdown data:", error);
    //         }
    //     };
    //
    //     fetchDropdownData();
    // }, []);
    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const [scopes, constitutions, countries, orderCodes, lodges, eventTypes, documentTypes, fileTypes, roles] = await Promise.all([
                    axios.get("/api/scopes"),
                    axios.get("/api/constitutions"),
                    axios.get("/api/countries"),
                    axios.get("/api/order-codes"),
                    axios.get("/api/lodges"),
                    axios.get("/api/event-types"),
                    axios.get("/api/document-types"),
                    axios.get("/api/file-types"),
                    axios.get("/api/roles"),
                ]);

                const roleOptions = roles.data.map((role) => ({ value: role.id, label: role.name }));

                setDropdowns({
                    scopes: scopes.data.map((scope) => ({ value: scope.code, label: scope.description })),
                    constitutions: constitutions.data.map((constitution) => ({ value: constitution.code, label: constitution.description })),
                    countries: countries.data.map((country) => ({ value: country.code, label: country.name })),
                    orderCodes: orderCodes.data.map((orderCode) => ({ value: orderCode.code, label: orderCode.description })),
                    lodges: lodges.data.map((lodge) => ({ value: lodge.code, label: lodge.name })),
                    eventTypes: eventTypes.data.map((eventType) => ({ value: eventType.code, label: eventType.description })),
                    documentTypes: documentTypes.data.map((docType) => ({ value: docType.code, label: docType.description })),
                    fileTypes: fileTypes.data.map((fileType) => ({ value: fileType.code, label: fileType.description })),
                    roles: roleOptions,
                });

                // Set all roles as selected by default
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    roles: roleOptions.map((role) => role.value), // Select all roles by their IDs
                }));
            } catch (error) {
                console.error("Error fetching dropdown data:", error);
            }
        };

        fetchDropdownData();
    }, []);

    // Handle file selection
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Handle metadata input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // If changing scope, reset lodge-specific fields
        if (name === "scope") {
            setFormData({
                ...formData,
                [name]: value,
                orderCode: "",
                lodge: "",
            });
        } else if (name === "roles") {
            // Handle multi-select for roles
            const options = Array.from(e.target.selectedOptions, (option) => option.value);
            setFormData((prevData) => ({
                ...prevData,
                roles: options,
            }));
        } else {
            setFormData({ ...formData, [name]: value });
        }

        // else {
        //     setFormData({ ...formData, [name]: value });
        // }
    };

    // Validate form data before submission
    const validateForm = () => {
        const newErrors = {};

        const requiredFields = ["scope", "constitution", "country", "eventType", "documentType", "fileType", "date", "version", "title", "description"];

        requiredFields.forEach((field) => {
            if (!formData[field]) {
                newErrors[field] = "This field is required";
            }
        });

        if (formData.scope === "LOD") {
            if (!formData.orderCode) {
                newErrors.orderCode = "Order Code is required when Scope is 'LOD'";
            }
            if (!formData.lodge) {
                newErrors.lodge = "Lodge is required when Scope is 'LOD'";
            }
        }
        if (formData.roles.length === 0) {
            newErrors.roles = "Please select at least one role.";
        }

        if (!file) {
            newErrors.file = "Please select a file to upload";
        }

        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log("Form submission started.");

        // Log form data before validation
        console.log("Current form data before validation:", formData);
        console.log("Selected file:", file);

        if (!validateForm()) {
            console.log("Form validation failed. Errors:", errors);
            return;
        }
        console.log("Form validation passed.");

        const data = new FormData();
        Object.keys(formData).forEach((key) => {
            if (key === "roles") {
                // Add roles as a comma-separated string
                data.append(key, formData[key].join(","));
            } else {
                data.append(key, formData[key]);
            }
            // data.append(key, formData[key]);
        });
        data.append("document", file);

        // Log FormData entries for debugging
        console.log("FormData entries being sent to server:");
        for (let pair of data.entries()) {
            if (pair[0] === "document") {
                console.log(`${pair[0]}: [File object with name ${pair[1]?.name}]`);
            } else {
                console.log(`${pair[0]}: ${pair[1]}`);
            }
        }

        try {
            console.log("Sending POST request to /api/upload...");
            const response = await axios.post("/api/upload", data, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                timeout: 30000, // Set a generous timeout for large files
            });

            console.log("Server responded successfully:", response);
            alert(response.data.message);

            // Reset form state after successful upload
            setFormData({
                scope: "LOD",
                constitution: "",
                country: "",
                orderCode: "",
                lodge: "",
                eventType: "",
                documentType: "",
                fileType: "",
                date: "",
                version: "1",
                addendum: "",
                description: "",
                author_initials: "",
                doi: "",
                title: "",
                roles: dropdowns.roles.map((r) => r.id), // Reset roles to all selected
            });
            setFile(null);
            setErrors({});
            console.log("Form reset after successful upload.");
        } catch (error) {
            console.error("Error during file upload:");
            console.error("Error message:", error.message);

            if (error.response) {
                console.error("Server responded with an error:");
                console.error("Response status:", error.response.status);
                console.error("Response data:", error.response.data);
                console.error("Response headers:", error.response.headers);
            } else if (error.request) {
                console.error("No response received from the server:");
                console.error("Request details:", error.request);
            } else {
                console.error("Error setting up the request:", error.message);
            }

            console.error("Axios error configuration:", error.config);

            alert(error.response?.data?.error ? `File upload failed: ${error.response.data.error}` : "File upload failed due to an unknown error.");
        }
    };

    return (
        <div className="container3">
            <h2>Document Upload</h2>
            <form onSubmit={handleSubmit}>
                {/* General Information */}
                <div className="form-section3">
                    <h3>General Information</h3>
                    <div>
                        <label>Constitution:</label>
                        <Select name="constitution" options={dropdowns.constitutions} value={dropdowns.constitutions.find((item) => item.value === formData.constitution) || null} onChange={(selectedOption) => setFormData({ ...formData, constitution: selectedOption?.value || "" })} placeholder="Select Constitution" isClearable isSearchable />
                        {errors.constitution && <span className="error">{errors.constitution}</span>}
                    </div>
                    <div>
                        <label>Country:</label>
                        <Select name="country" options={dropdowns.countries} value={dropdowns.countries.find((item) => item.value === formData.country) || null} onChange={(selectedOption) => setFormData({ ...formData, country: selectedOption?.value || "" })} placeholder="Select Country" isClearable isSearchable />
                        {errors.country && <span className="error3">{errors.country}</span>}
                    </div>
                </div>

                {/* Lodge-Specific Fields */}
                {formData.scope === "LOD" && (
                    <div className="form-section3">
                        <h3>Lodge Information</h3>
                        <div>
                            <label>Order Code:</label>
                            <Select name="orderCode" options={dropdowns.orderCodes} value={dropdowns.orderCodes.find((item) => item.value === formData.orderCode) || null} onChange={(selectedOption) => setFormData({ ...formData, orderCode: selectedOption?.value || "" })} placeholder="Select Order Code" isClearable isSearchable />
                            {errors.orderCode && <span className="error3">{errors.orderCode}</span>}
                        </div>
                        <div>
                            <label>Entity:</label>
                            <Select name="lodge" options={dropdowns.lodges} value={dropdowns.lodges.find((item) => item.value === formData.lodge) || null} onChange={(selectedOption) => setFormData({ ...formData, lodge: selectedOption?.value || "" })} placeholder="Select Entity" isClearable isSearchable />
                            {errors.lodge && <span className="error3">{errors.lodge}</span>}
                        </div>
                    </div>
                )}

                {/* Document Details */}
                <div className="form-section3">
                    <h3>Document Details</h3>
                    <div>
                        <label>Event Type:</label>
                        <Select name="eventType" options={dropdowns.eventTypes} value={dropdowns.eventTypes.find((item) => item.value === formData.eventType) || null} onChange={(selectedOption) => setFormData({ ...formData, eventType: selectedOption?.value || "" })} placeholder="Select Event Type" isClearable isSearchable />
                        {errors.eventType && <span className="error3">{errors.eventType}</span>}
                    </div>
                    <div>
                        <label>Document Type:</label>
                        <Select name="documentType" options={dropdowns.documentTypes} value={dropdowns.documentTypes.find((item) => item.value === formData.documentType) || null} onChange={(selectedOption) => setFormData({ ...formData, documentType: selectedOption?.value || "" })} placeholder="Select Document Type" isClearable isSearchable />
                        {errors.documentType && <span className="error3">{errors.documentType}</span>}
                    </div>
                    <div>
                        <label>File Type:</label>
                        <Select name="fileType" options={dropdowns.fileTypes} value={dropdowns.fileTypes.find((item) => item.value === formData.fileType) || null} onChange={(selectedOption) => setFormData({ ...formData, fileType: selectedOption?.value || "" })} placeholder="Select File Type" isClearable isSearchable />
                        {errors.fileType && <span className="error3">{errors.fileType}</span>}
                    </div>
                    <div>
                        <label>Date:</label>
                        <input type="date" name="date" value={formData.date} onChange={handleInputChange} required />
                        {errors.date && <span className="error3">{errors.date}</span>}
                    </div>
                    <div>
                        <label>Version:</label>
                        <input type="text" name="version" value={formData.version} onChange={handleInputChange} required />
                        {errors.version && <span className="error3">{errors.version}</span>}
                    </div>
                    <div>
                        <label>Addendum (Optional):</label>
                        <input type="text" name="addendum" value={formData.addendum} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label>Title:</label>
                        <input type="text" name="title" value={formData.title} onChange={handleInputChange} required />
                        {errors.title && <span className="error3">{errors.title}</span>}
                    </div>
                    <div>
                        <label>Description:</label>
                        <textarea name="description" value={formData.description} onChange={handleInputChange} required />
                        {errors.description && <span className="error3">{errors.description}</span>}
                    </div>
                    <div>
                        <label>Authors (Optional):</label>
                        <input type="text" name="author_initials" value={formData.author_initials} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label>DOI (Optional):</label>
                        <input type="text" name="doi" value={formData.doi} onChange={handleInputChange} />
                    </div>
                    <div>
                        <label>Roles (select/unselect):</label>
                        <div className="react-select-container3">
                            <Select
                                name="roles"
                                options={dropdowns.roles}
                                isMulti
                                value={dropdowns.roles.filter((role) => formData.roles.includes(role.value))}
                                onChange={(selectedOptions) => {
                                    setFormData({
                                        ...formData,
                                        roles: selectedOptions.map((option) => option.value),
                                    });
                                }}
                                placeholder="Select Roles"
                                classNamePrefix="react-select3"
                            />
                        </div>
                        {errors.roles && <span className="error3">{errors.roles}</span>}
                    </div>
                </div>

                {/* File Upload */}
                <div className="form-section3">
                    <h3>Upload File</h3>
                    <div>
                        <label>Document File:</label>
                        <input type="file" onChange={handleFileChange} required />
                        {errors.file && <span className="error3">{errors.file}</span>}
                    </div>
                </div>

                <button type="submit">Upload Document</button>
            </form>
        </div>
    );
}

export default DocumentManagement;
