import React from "react";
import { useNavigate } from "react-router-dom";
import "./UnderConstruction.css";
import constructionImage from "./construction.png"; // Replace with your image path

function UnderConstruction({ title = "Page Under Construction" }) {
    const navigate = useNavigate();

    return (
        <div className="under-construction">
            <img
                src={constructionImage}
                alt="Under Construction"
                className="construction-image"
            />
            <h1>{title}</h1>
            <p>We're working hard to bring you this page. Stay tuned for updates!</p>
            <button
                className="back-home-button"
                onClick={() => navigate("/")}
            >
                Back to Home
            </button>
        </div>
    );
}

export default UnderConstruction;
