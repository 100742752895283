import React, { useState } from "react";
import "./InstructionsPage.css";

function InstructionsPage() {
    const [expandedSection, setExpandedSection] = useState(null);
    const [expandedSubsection, setExpandedSubsection] = useState(null);
    const [expandedSubSubsection, setExpandedSubSubsection] = useState(null);

    const toggleSection = (section) => {
        setExpandedSection(expandedSection === section ? null : section);
    };

    const toggleSubsection = (subsection) => {
        setExpandedSubsection(expandedSubsection === subsection ? null : subsection);
    };

    const toggleSubSubsection = (subSubsection) => {
        setExpandedSubSubsection(expandedSubSubsection === subSubsection ? null : subSubsection);
    };

    return (
        <div className="instructions-page">
            <h1>Instructions</h1>

            {/* General Navigation */}
            <div className="instruction-section">
                <button
                    className="section-toggle"
                    onClick={() => toggleSection("general-navigation")}
                >
                    General Navigation {expandedSection === "general-navigation" ? "-" : "+"}
                </button>
                {expandedSection === "general-navigation" && (
                    <div className="section-content">
                        <h2>General Navigation</h2>
                        <p>Learn how to navigate through the platform's key features:</p>
                        {/*<ul>*/}
                        {/*    <li>Home: Access all main sections of the site from here.</li>*/}
                        {/*    <li>Login: Sign in to unlock features based on your role.</li>*/}
                        {/*    <li>Navigation Menu: Use the top bar to quickly switch between sections.</li>*/}
                        {/*</ul>*/}
                    </div>
                )}
            </div>

            {/* About Section */}
            <div className="instruction-section">
                <button
                    className="section-toggle"
                    onClick={() => toggleSection("about-section")}
                >
                    About {expandedSection === "about-section" ? "-" : "+"}
                </button>
                {expandedSection === "about-section" && (
                    <div className="section-content">
                        <h2>About</h2>
                        <p>The About section provides a detailed overview of our mission and history:</p>
                        {/*<ul>*/}
                        {/*    <li>Learn about the Connaught Research Lodge's founding.</li>*/}
                        {/*    <li>Explore our contributions to Masonic research.</li>*/}
                        {/*    <li>Read about ongoing projects and collaborations.</li>*/}
                        {/*</ul>*/}
                    </div>
                )}
            </div>

            {/* Research Section */}
            <div className="instruction-section">
                <button
                    className="section-toggle"
                    onClick={() => toggleSection("research-section")}
                >
                    Research {expandedSection === "research-section" ? "-" : "+"}
                </button>
                {expandedSection === "research-section" && (
                    <div className="section-content">
                        <h2>Research</h2>
                        {/*<p>The About section provides a detailed overview of our mission and history:</p>*/}
                        {/*<ul>*/}
                        {/*    <li>Learn about the Connaught Research Lodge's founding.</li>*/}
                        {/*    <li>Explore our contributions to Masonic research.</li>*/}
                        {/*    <li>Read about ongoing projects and collaborations.</li>*/}
                        {/*</ul>*/}
                    </div>
                )}
            </div>

            {/* Resources Section */}
            <div className="instruction-section">
                <button
                    className="section-toggle"
                    onClick={() => toggleSection("resources-section")}
                >
                    Resources {expandedSection === "resources-section" ? "-" : "+"}
                </button>
                {expandedSection === "resources-section" && (
                    <div className="section-content">
                        <h2>Resources</h2>
                        <p>Explore tools and data available under the Resources section:</p>

                        {/* Subsection: Temples */}
                        <div className="subsection">
                            <button
                                className="subsection-toggle"
                                onClick={() => toggleSubsection("temples-subsection")}
                            >
                                Temples {expandedSubsection === "temples-subsection" ? "-" : "+"}
                            </button>
                            {expandedSubsection === "temples-subsection" && (
                                <div className="subsection-content">
                                    <h3>Managing Temples</h3>
                                    <p>
                                        The Temples subsection allows authorized users to manage and view Masonic temples.
                                        Follow these steps to ensure accurate record-keeping:
                                    </p>
                                    <h4>Features Overview</h4>
                                    <ul>
                                        <li><strong>Select Existing Temple:</strong> Use the dropdown to choose a temple to view or edit.</li>
                                        <li><strong>Add New Temple:</strong> Choose the "Add New Building" option to create a new temple record.</li>
                                        <li><strong>View and Edit Details:</strong> Access fields for name, location, capacity, historical status, etc.</li>
                                        <li><strong>Link Documents:</strong> Attach document IDs related to the temple.</li>
                                    </ul>
                                    <h4>Steps</h4>
                                    <ol>
                                        <li>Select a temple from the dropdown or choose "Add New Building".</li>
                                        <li>Fill in or update the form fields.</li>
                                        <li>Click <strong>Save</strong> to save changes or <strong>Delete</strong> to remove the temple.</li>
                                        <li>Use the <strong>Cancel</strong> button to exit without saving changes.</li>
                                    </ol>
                                </div>
                            )}
                        </div>

                        {/* Subsection: Lodges */}
                        <div className="subsection">
                            <button
                                className="subsection-toggle"
                                onClick={() => toggleSubsection("lodges-subsection")}
                            >
                                Lodges {expandedSubsection === "lodges-subsection" ? "-" : "+"}
                            </button>
                            {expandedSubsection === "lodges-subsection" && (
                                <div className="subsection-content">
                                    <h3>Instructions for the Lodges Subsection</h3>

                                    <h4>Purpose</h4>
                                    <p>
                                        The Lodges subsection provides users with a detailed overview of lodge-related information.
                                        It allows authorized users to view and edit existing lodge data while preserving lodge histories.
                                        This system ensures that if a lodge moves to a new building or changes status, its historical data remains intact.
                                        New lodges must be added via the Admin Panel.
                                    </p>

                                    <h4>Features Overview</h4>
                                    <ul>
                                        <li>
                                            <strong>View Lodge Details:</strong> Access comprehensive details for each lodge, including its type, constitution, and associated building.
                                        </li>
                                        <li>
                                            <strong>Edit Lodge Information:</strong> Modify existing lodge data, such as lodge name, type, constitution, and status.
                                        </li>
                                        <li>
                                            <strong>Data Relationships:</strong> Lodges are linked to:
                                            <ul>
                                                <li><strong>Buildings:</strong> The physical location of the lodge, managed in the Temples section.</li>
                                                <li><strong>Lodge Types:</strong> Classifications, such as:
                                                    <ul>
                                                        <li><strong>Grand Lodge (United Grand Lodge):</strong> </li>
                                                        <li><strong>Provincial/District Grand Lodge:</strong> </li>
                                                        <li><strong>Craft Lodge (Local Level):</strong> </li>
                                                        <li><strong>Research:</strong> </li>
                                                        <li><strong>Royal Arch Chapter (Local):</strong> </li>
                                                        <li><strong>etc...</strong> </li>

                                                    </ul>
                                                </li>
                                                <li><strong>Constitutions:</strong> Governing bodies or orders associated with the lodge.</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Document Linking:</strong> Attach related document IDs to lodges for easy reference (e.g., warrants, general documents).
                                        </li>
                                        <li>
                                            <strong>Actions:</strong>
                                            <ul>
                                                <li><strong>Save Changes:</strong> Save updates made to the lodge details.</li>
                                                <li><strong>Cancel:</strong> Exit without saving changes.</li>
                                            </ul>
                                        </li>
                                    </ul>

                                    <h4>Step-by-Step Instructions</h4>

                                    <h5>Viewing Lodge Details</h5>
                                    <ol>
                                        <li>Use the dropdown to select an existing lodge.</li>
                                        <li>View details such as:
                                            <ul>
                                                <li>Lodge code and name.</li>
                                                <li>Year established and decommissioned (if applicable).</li>
                                                <li>Status and related documents.</li>
                                                <li>Building, lodge type, and constitution associations.</li>
                                            </ul>
                                        </li>
                                    </ol>

                                    <h5>Editing Lodge Details</h5>
                                    <ol>
                                        <li>Select the lodge you want to edit from the dropdown.</li>
                                        <li>Modify the desired fields:
                                            <ul>
                                                <li>Lodge code, name, or year established.</li>
                                                <li>Status (active, inactive, etc.).</li>
                                                <li>Associated building, lodge type, and constitution.</li>
                                            </ul>
                                        </li>
                                        <li>Save changes by clicking the <strong>Save</strong> button.</li>
                                    </ol>

                                    <h5>Adding New Lodges</h5>
                                    <p>
                                        Adding new lodges is restricted to the Admin Panel:
                                    </p>
                                    <ol>
                                        <li>Navigate to the Admin Panel.</li>
                                        <li>Select the <strong>Entity (Lodges)</strong> table from the dropdown.</li>
                                        <li>Use the <strong>Add New</strong> button to input details for the new lodge.</li>
                                        <li>Save the new lodge entry, which will then appear in the Lodges subsection.</li>
                                    </ol>

                                    <h5>Linking Documents</h5>
                                    <ol>
                                        <li>Use the input fields for <strong>Warrant Document IDs</strong> and <strong>General Document IDs</strong> to associate relevant documents.</li>
                                        <li>Enter document IDs as a comma-separated list (these can be copied from the view documents section for any document associated with the lodge).</li>
                                    </ol>

                                    <h4>Tips for Managing Lodges</h4>
                                    <ul>
                                        <li>Ensure all fields are accurately filled out to maintain data consistency.</li>
                                        <li>Use the search functionality in the Admin Panel to locate specific lodges quickly.</li>
                                        <li>
                                            Verify document IDs and relationships (buildings, types, constitutions) before saving changes.
                                            The linkage between lodges and buildings is managed via the <strong>Temples</strong> section, ensuring consistency in physical location data.
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>

                        {/* Subsection: Members */}
                        <div className="subsection">
                            <button
                                className="subsection-toggle"
                                onClick={() => toggleSubsection("members-subsection")}
                            >
                                Members {expandedSubsection === "members-subsection" ? "-" : "+"}
                            </button>
                            {expandedSubsection === "members-subsection" && (
                                <div className="subsection-content">
                                    <h3>Instructions for the Members Subsection</h3>

                                    <h4>Purpose</h4>
                                    <p>
                                        The Members subsection allows authorized users to manage member information,
                                        view membership histories, track degrees and roles, and maintain a detailed record of Masonic members.
                                        This system ensures consistency and accuracy in member data management.
                                    </p>

                                    <h4>Features Overview</h4>
                                    <ul>
                                        <li>
                                            <strong>View Member Profiles:</strong> Access comprehensive details for each member, including personal information and related records.
                                        </li>
                                        <li>
                                            <strong>Manage Membership History:</strong> Track membership changes over time, including transfers between lodges or resignations.
                                        </li>
                                        <li>
                                            <strong>Manage Degrees:</strong> Record and update degrees or certifications held by a member.
                                        </li>
                                        <li>
                                            <strong>Track Officer Roles:</strong> Maintain a history of officer roles and ranks held by a member.
                                        </li>
                                        <li>
                                            <strong>Country and Location Data:</strong> Record the member's country, address, and other contact information.
                                        </li>
                                        <li>
                                            <strong>Actions:</strong>
                                            <ul>
                                                <li><strong>Save Changes:</strong> Save updates made to the member details.</li>
                                                <li><strong>Delete Member:</strong> Remove a member record permanently.</li>
                                                <li><strong>Cancel:</strong> Exit without saving changes.</li>
                                            </ul>
                                        </li>
                                    </ul>

                                    <h4>Step-by-Step Instructions</h4>

                                    <h5>Viewing Member Profiles</h5>
                                    <ol>
                                        <li>Use the dropdown to select an existing member.</li>
                                        <li>View details such as:
                                            <ul>
                                                <li>Full name and contact information.</li>
                                                <li>Date of birth and date of death (if applicable).</li>
                                                <li>Membership status, history, and related documents.</li>
                                                <li>Officer roles, degrees, and rank information.</li>
                                            </ul>
                                        </li>
                                    </ol>

                                    <h5>Editing Member Details</h5>
                                    <ol>
                                        <li>Select the member you want to edit from the dropdown.</li>
                                        <li>Modify the desired fields:
                                            <ul>
                                                <li>First name, last name, or contact information.</li>
                                                <li>Membership dates and associated documents.</li>
                                                <li>Country and address details.</li>
                                            </ul>
                                        </li>
                                        <li>Save changes by clicking the <strong>Save</strong> button.</li>
                                    </ol>

                                    <h5>Deleting Members</h5>
                                    <ol>
                                        <li>Select the member you want to delete from the dropdown.</li>
                                        <li>Click the <strong>Delete</strong> button and confirm the deletion in the pop-up.</li>
                                        <li>The member will be permanently removed from the database.</li>
                                    </ol>

                                    <h4>Tips for Managing Members</h4>
                                    <ul>
                                        <li>Ensure all required fields are filled out to maintain data consistency.</li>
                                        <li>Use the search functionality to quickly locate a specific member.</li>
                                        <li>Verify all linked records (membership history, degrees, officer roles) before saving changes.</li>
                                    </ul>



                                    <div className="sub-subsection">
                                        <button
                                            className="subsection-toggle"
                                            onClick={() => toggleSubSubsection("membership-history")}
                                        >
                                            Membership History {expandedSubSubsection === "membership-history" ? "-" : "+"}
                                        </button>
                                        {expandedSubSubsection === "membership-history" && (
                                            <div className="subsection-content">
                                                <h5>Membership History</h5>
                                                <p>
                                                    The <strong>Membership History</strong> section allows users to manage and track the historical affiliations of a member with lodges. This includes viewing a list of past and current memberships, adding new entries, editing existing records, and deleting outdated or incorrect data. The history is essential for maintaining accurate records of a member's journey and contributions within the organization.
                                                </p>

                                                <h6>Features Overview</h6>
                                                <ul>
                                                    <li>
                                                        <strong>View Membership History:</strong> Displays a list of all historical and current memberships for the selected member. Each record includes:
                                                        <ul>
                                                            <li>Lodge name</li>
                                                            <li>Membership type</li>
                                                            <li>Membership status</li>
                                                            <li>Start and end dates</li>
                                                            <li>Comments</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>Edit Membership History:</strong> Modify details of an existing membership record, such as lodge, type, status, dates, and comments.
                                                    </li>
                                                    <li>
                                                        <strong>Add New History Record:</strong> Create a new history entry for a member by filling out the required fields.
                                                    </li>
                                                    <li>
                                                        <strong>Delete Membership Record:</strong> Remove an outdated or incorrect record from the history list, with confirmation to prevent accidental deletions.
                                                    </li>
                                                    <li>
                                                        <strong>Dropdown Selectors:</strong> Use dropdowns for selecting lodges, membership types, and statuses, ensuring consistent and valid data entry.
                                                    </li>
                                                    <li>
                                                        <strong>Manage Missing Types:</strong> If a lodge, membership type, or status is missing from the dropdowns:
                                                        <ul>
                                                            <li>Navigate to the <strong>Admin Panel</strong>.</li>
                                                            <li>Select the appropriate category (Lodges, Membership Types, or Membership Statuses).</li>
                                                            <li>Add the missing entry using the <strong>Add New</strong> button.</li>
                                                            <li>The new type will then be available in the dropdowns.</li>
                                                        </ul>
                                                    </li>
                                                </ul>

                                                <h6>Step-by-Step Instructions</h6>
                                                <h6>Viewing Membership History</h6>
                                                <ol>
                                                    <li>Select the member whose history you want to view.</li>
                                                    <li>Navigate to the <strong>Membership History</strong> section.</li>
                                                    <li>The history list will display all entries, showing:
                                                        <ul>
                                                            <li>Lodge name</li>
                                                            <li>Membership type and status</li>
                                                            <li>Start and end dates</li>
                                                            <li>Comments</li>
                                                        </ul>
                                                    </li>
                                                </ol>

                                                <h6>Adding a New Membership Record</h6>
                                                <ol>
                                                    <li>Click on the <strong>Add New</strong> button.</li>
                                                    <li>Fill out the form fields:
                                                        <ul>
                                                            <li><strong>Lodge:</strong> Select the lodge from the dropdown.</li>
                                                            <li><strong>Membership Type:</strong> Choose the appropriate type from the dropdown.</li>
                                                            <li><strong>Membership Status:</strong> Select the current status of the membership.</li>
                                                            <li><strong>Start Date:</strong> Enter the start date in YYYY-MM-DD format.</li>
                                                            <li><strong>End Date:</strong> (Optional) Enter the end date if applicable.</li>
                                                            <li><strong>Comments:</strong> (Optional) Add any relevant notes.</li>
                                                        </ul>
                                                    </li>
                                                    <li>Click <strong>Save</strong> to add the new record to the history list.</li>
                                                </ol>

                                                <h6>Editing an Existing Membership Record</h6>
                                                <ol>
                                                    <li>Locate the record you wish to edit in the history list.</li>
                                                    <li>Click the <strong>Edit</strong> button next to the record.</li>
                                                    <li>Update the form fields as needed and click <strong>Save</strong>.</li>
                                                </ol>

                                                <h6>Deleting a Membership Record</h6>
                                                <ol>
                                                    <li>Identify the record you wish to delete in the history list.</li>
                                                    <li>Click the <strong>Delete</strong> button and confirm the deletion in the pop-up window.</li>
                                                    <li>The record will be permanently removed from the list.</li>
                                                </ol>

                                                <h6>Tips for Managing Membership History</h6>
                                                <ul>
                                                    <li><strong>Ensure Data Accuracy:</strong> Double-check fields like lodge name, type, and status before saving.</li>
                                                    <li><strong>Start and End Dates:</strong> Always enter valid dates to maintain a clear timeline of membership.</li>
                                                    <li><strong>Use Comments:</strong> Add explanatory notes for unique cases or exceptions.</li>
                                                    <li><strong>Avoid Accidental Deletion:</strong> Verify the record before confirming its removal.</li>
                                                    <li><strong>Missing Types:</strong> If a required lodge, membership type, or status is not available, ensure it is added in the <strong>Admin Panel</strong> first.</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>


                                    <div className="sub-subsection">
                                        <button
                                            className="subsection-toggle"
                                            onClick={() => toggleSubSubsection("degrees-subsection")}
                                        >
                                            Degrees {expandedSubSubsection === "degrees-subsection" ? "-" : "+"}
                                        </button>
                                        {expandedSubSubsection === "degrees-subsection" && (
                                            <div className="subsection-content">
                                                <h5>Degrees</h5>
                                                <p>
                                                    The <strong>Degrees</strong> section allows users to manage the degrees and certifications earned by a member.
                                                    This includes associating degrees with specific membership histories, tracking the date obtained, and linking associated documents.
                                                </p>

                                                <h6>Features Overview</h6>
                                                <ul>
                                                    <li>
                                                        <strong>View Saved Degrees:</strong> A list of all degrees associated with the selected member. Each record displays:
                                                        <ul>
                                                            <li>The degree name</li>
                                                            <li>The date obtained</li>
                                                            <li>Comments and associated documents</li>
                                                        </ul>
                                                        Users can edit or delete these records directly from the list.
                                                    </li>
                                                    <li>
                                                        <strong>Add New Degree:</strong> Add a new degree by filling out the required fields, including:
                                                        <ul>
                                                            <li>Membership history (linked to a lodge and membership type)</li>
                                                            <li>Degree name</li>
                                                            <li>Date obtained</li>
                                                            <li>Comments</li>
                                                            <li>Associated documents</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>Edit Existing Degree:</strong> Modify an existing degree record, including updating details such as degree name or associated documents.
                                                    </li>
                                                    <li>
                                                        <strong>Delete Degree:</strong> Permanently remove a degree from the member’s history.
                                                    </li>
                                                    <li>
                                                        <strong>Dropdown Selectors:</strong> Use dropdowns to ensure data consistency when selecting membership history and degree names.
                                                        <ul>
                                                            <li>The <strong>Membership History</strong> dropdown is populated with data from the <strong>Membership History</strong> section.</li>
                                                            <li>If the required degree is not available, it can be added via the <strong>Admin Panel</strong>.</li>
                                                        </ul>
                                                    </li>
                                                </ul>

                                                <h6>Step-by-Step Instructions</h6>

                                                <h6>Viewing Saved Degrees</h6>
                                                <ol>
                                                    <li>Select the member whose degrees you want to view.</li>
                                                    <li>The saved degrees list will display all degrees, showing:
                                                        <ul>
                                                            <li>Degree name</li>
                                                            <li>Date obtained</li>
                                                            <li>Comments and associated documents</li>
                                                        </ul>
                                                    </li>
                                                    <li>Use the <strong>Edit</strong> or <strong>Delete</strong> buttons to manage individual records.</li>
                                                </ol>

                                                <h6>Adding a New Degree</h6>
                                                <ol>
                                                    <li>Click the <strong>Add New Degree</strong> button.</li>
                                                    <li>Fill out the required fields:
                                                        <ul>
                                                            <li><strong>Membership History:</strong> Select the appropriate membership record (linked to a lodge and membership type).</li>
                                                            <li><strong>Degree:</strong> Choose the degree from the dropdown.</li>
                                                            <li><strong>Date Obtained:</strong> Enter the date the degree was obtained in YYYY-MM-DD format.</li>
                                                            <li><strong>Comments:</strong> (Optional) Add any relevant notes.</li>
                                                            <li><strong>Associated Documents:</strong> (Optional) Enter document IDs as a comma-separated list.</li>
                                                        </ul>
                                                    </li>
                                                    <li>Click <strong>Save</strong> to add the new degree.</li>
                                                </ol>

                                                <h6>Editing an Existing Degree</h6>
                                                <ol>
                                                    <li>Locate the degree you wish to edit in the saved degrees list.</li>
                                                    <li>Click the <strong>Edit</strong> button.</li>
                                                    <li>Update the necessary fields, such as:
                                                        <ul>
                                                            <li>Membership history</li>
                                                            <li>Degree name</li>
                                                            <li>Date obtained</li>
                                                            <li>Comments or associated documents</li>
                                                        </ul>
                                                    </li>
                                                    <li>Click <strong>Save</strong> to update the record.</li>
                                                </ol>

                                                <h6>Deleting a Degree</h6>
                                                <ol>
                                                    <li>Identify the degree you wish to delete in the saved degrees list.</li>
                                                    <li>Click the <strong>Delete</strong> button and confirm the deletion in the pop-up.</li>
                                                    <li>The degree will be permanently removed from the member’s record.</li>
                                                </ol>

                                                <h6>Tips for Managing Degrees</h6>
                                                <ul>
                                                    <li><strong>Membership History:</strong> Always ensure that a membership history is selected before associating a degree. This ensures proper linkage with lodges and membership types.</li>
                                                    <li><strong>Dropdown Values:</strong> If a required degree is missing, it must be added in the <strong>Admin Panel</strong>.</li>
                                                    <li><strong>Date Format:</strong> Enter valid dates in YYYY-MM-DD format to maintain consistency.</li>
                                                    <li><strong>Comments and Documents:</strong> Use these fields to provide additional context for unique degrees.</li>
                                                    <li><strong>Avoid Accidental Deletion:</strong> Verify the degree before confirming its removal.</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>


                                    <div className="sub-subsection">
                                        <button
                                            className="subsection-toggle"
                                            onClick={() => toggleSubSubsection("officer-roles")}
                                        >
                                            Officer Roles {expandedSubSubsection === "officer-roles" ? "-" : "+"}
                                        </button>
                                        {expandedSubSubsection === "officer-roles" && (
                                            <div className="subsection-content">
                                                <h5>Officer Roles</h5>
                                                <p>
                                                    The <strong>Officer Roles</strong> section allows users to record and manage officer roles and ranks held by a member,
                                                    including the start and end dates of their service. Each role is associated with a specific lodge and membership history.
                                                </p>

                                                <h6>Features Overview</h6>
                                                <ul>
                                                    <li>
                                                        <strong>View Officer History:</strong> Displays a list of all officer roles held by the member, including:
                                                        <ul>
                                                            <li>Lodge name</li>
                                                            <li>Officer role and rank</li>
                                                            <li>Dates of service</li>
                                                            <li>Comments</li>
                                                        </ul>
                                                        Users can edit or delete records directly from the list.
                                                    </li>
                                                    <li>
                                                        <strong>Add New Officer Role:</strong> Users can assign a new officer role to a member by selecting the appropriate:
                                                        <ul>
                                                            <li>Membership history (linked to the lodge and membership details)</li>
                                                            <li>Lodge</li>
                                                            <li>Officer role</li>
                                                            <li>Rank</li>
                                                            <li>Start and end dates</li>
                                                            <li>Comments (optional)</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>Edit Existing Role:</strong> Modify the details of an existing officer role record.
                                                    </li>
                                                    <li>
                                                        <strong>Delete Officer Role:</strong> Remove an officer role from the member’s history permanently.
                                                    </li>
                                                    <li>
                                                        <strong>Dropdown Management:</strong>
                                                        <ul>
                                                            <li>The <strong>Membership History</strong> dropdown is populated in the <strong>Membership History</strong> section.</li>
                                                            <li>If a required lodge, role, or rank is missing, these can be added via the <strong>Admin Panel</strong>.</li>
                                                        </ul>
                                                    </li>
                                                </ul>

                                                <h6>Step-by-Step Instructions</h6>

                                                <h6>Viewing Officer History</h6>
                                                <ol>
                                                    <li>Select the member whose officer roles you wish to view.</li>
                                                    <li>The list of officer roles will display the following for each record:
                                                        <ul>
                                                            <li>Lodge name</li>
                                                            <li>Officer role and rank</li>
                                                            <li>Start and end dates</li>
                                                            <li>Comments</li>
                                                        </ul>
                                                    </li>
                                                    <li>Use the <strong>Edit</strong> or <strong>Delete</strong> buttons to manage individual records.</li>
                                                </ol>

                                                <h6>Adding a New Officer Role</h6>
                                                <ol>
                                                    <li>Click the <strong>Add New</strong> button in the form section.</li>
                                                    <li>Fill out the required fields:
                                                        <ul>
                                                            <li><strong>Membership History:</strong> Select a history record linked to a lodge and membership details.</li>
                                                            <li><strong>Lodge:</strong> Choose the lodge where the role was held.</li>
                                                            <li><strong>Officer Role:</strong> Select the specific role held by the member.</li>
                                                            <li><strong>Rank:</strong> Assign the member’s rank for the role.</li>
                                                            <li><strong>Start Date:</strong> Enter the date the member started the role (YYYY-MM-DD).</li>
                                                            <li><strong>End Date:</strong> (Optional) Enter the date the member ended the role (YYYY-MM-DD).</li>
                                                            <li><strong>Comments:</strong> (Optional) Provide additional context or details about the role.</li>
                                                        </ul>
                                                    </li>
                                                    <li>Click <strong>Save</strong> to add the new officer role to the member’s history.</li>
                                                </ol>

                                                <h6>Editing an Existing Officer Role</h6>
                                                <ol>
                                                    <li>Locate the officer role you wish to edit in the list.</li>
                                                    <li>Click the <strong>Edit</strong> button next to the record.</li>
                                                    <li>Update the required fields, such as:
                                                        <ul>
                                                            <li>Lodge</li>
                                                            <li>Officer role</li>
                                                            <li>Rank</li>
                                                            <li>Start or end dates</li>
                                                            <li>Comments</li>
                                                        </ul>
                                                    </li>
                                                    <li>Click <strong>Save</strong> to apply the changes.</li>
                                                </ol>

                                                <h6>Deleting an Officer Role</h6>
                                                <ol>
                                                    <li>Identify the officer role you want to delete in the list.</li>
                                                    <li>Click the <strong>Delete</strong> button next to the record.</li>
                                                    <li>Confirm the deletion in the pop-up prompt.</li>
                                                    <li>The role will be permanently removed from the member’s record.</li>
                                                </ol>

                                                <h6>Tips for Managing Officer Roles</h6>
                                                <ul>
                                                    <li><strong>Membership History:</strong> Ensure the membership history is correctly selected to maintain data integrity.</li>
                                                    <li><strong>Dropdown Management:</strong> If a required lodge, role, or rank is not available in the dropdown, add it via the <strong>Admin Panel</strong>.</li>
                                                    <li><strong>Date Format:</strong> Always use YYYY-MM-DD format for start and end dates.</li>
                                                    <li><strong>Data Verification:</strong> Double-check the details before saving or deleting a record to avoid errors.</li>
                                                    <li><strong>Consistent Comments:</strong> Use comments to provide additional context, especially for unique or significant roles.</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>




                                    <div className="sub-subsection">
                                        <button
                                            className="subsection-toggle"
                                            onClick={() => toggleSubSubsection("profile-subsection")}
                                        >
                                            Member Profile {expandedSubSubsection === "profile-subsection" ? "-" : "+"}
                                        </button>
                                        {expandedSubSubsection === "profile-subsection" && (
                                            <div className="subsection-content">
                                                <h5>Member Profile</h5>
                                                <p>
                                                    The <strong>Member Profile</strong> section provides a detailed view of the selected member's personal details, membership history, roles and ranks, degrees earned, and meetings attended. Users can also export this information as a PDF report for record-keeping.
                                                </p>

                                                <h6>Features Overview</h6>
                                                <ul>
                                                    <li>
                                                        <strong>Personal Details:</strong> Displays the member's:
                                                        <ul>
                                                            <li>Full name</li>
                                                            <li>Date of birth and death</li>
                                                            <li>Contact information (phone, email, address)</li>
                                                            <li>Location details (city, state, country)</li>
                                                        </ul>
                                                    </li>
                                                    <li>
                                                        <strong>Membership History:</strong> Shows a chronological list of the member's affiliations, including:
                                                        <ul>
                                                            <li>Lodge name</li>
                                                            <li>Membership type</li>
                                                            <li>Status</li>
                                                            <li>Start and end dates</li>
                                                        </ul>
                                                        This data is managed in the <strong>Membership History</strong> section.
                                                    </li>
                                                    <li>
                                                        <strong>Roles and Ranks:</strong> Summarizes officer roles and ranks held by the member, including:
                                                        <ul>
                                                            <li>Lodge name</li>
                                                            <li>Role and rank</li>
                                                            <li>Dates of service</li>
                                                        </ul>
                                                        This data is managed in the <strong>Officer Roles</strong> section.
                                                    </li>
                                                    <li>
                                                        <strong>Degrees:</strong> Lists degrees earned by the member, including:
                                                        <ul>
                                                            <li>Degree name</li>
                                                            <li>Date obtained</li>
                                                        </ul>
                                                        This data is managed in the <strong>Degrees</strong> section.
                                                    </li>
                                                    <li>
                                                        <strong>Meetings Attended:</strong> Tracks meetings attended by the member, showing:
                                                        <ul>
                                                            <li>Total visits and meetings</li>
                                                            <li>Date, lodge, event type, and attendance type</li>
                                                        </ul>
                                                        This data is updated via the <strong>Meetings</strong> section when members are added to meetings.
                                                    </li>
                                                    <li>
                                                        <strong>Export as PDF:</strong> Generates a comprehensive PDF report of the member's profile, including all details and histories.
                                                    </li>
                                                </ul>

                                                <h6>Step-by-Step Instructions</h6>

                                                <h6>Viewing Member Profile</h6>
                                                <ol>
                                                    <li>Select the member whose profile you wish to view.</li>
                                                    <li>The profile will display the following sections:
                                                        <ul>
                                                            <li>Personal Details</li>
                                                            <li>Membership History</li>
                                                            <li>Roles and Ranks</li>
                                                            <li>Degrees</li>
                                                            <li>Meetings Attended</li>
                                                        </ul>
                                                    </li>
                                                </ol>

                                                <h6>Exporting Member Profile as PDF</h6>
                                                <ol>
                                                    <li>Click the <strong>Export as PDF</strong> button at the bottom of the profile view.</li>
                                                    <li>The system will generate a PDF report containing all the member's details, including:
                                                        <ul>
                                                            <li>Personal details</li>
                                                            <li>Membership history</li>
                                                            <li>Roles and ranks</li>
                                                            <li>Degrees</li>
                                                            <li>Meetings attended</li>
                                                        </ul>
                                                    </li>
                                                    <li>Save the PDF to your local device for record-keeping or sharing.</li>
                                                </ol>

                                                <h6>Managing Member Profile Data</h6>
                                                <p>
                                                    The profile data is populated and updated through the respective sections:
                                                </p>
                                                <ul>
                                                    <li>
                                                        <strong>Membership History:</strong> Managed in the <strong>Membership History</strong> section. Add or edit membership details as needed.
                                                    </li>
                                                    <li>
                                                        <strong>Roles and Ranks:</strong> Updated via the <strong>Officer Roles</strong> section. Assign officer roles and ranks to members.
                                                    </li>
                                                    <li>
                                                        <strong>Degrees:</strong> Managed in the <strong>Degrees</strong> section. Add new degrees or edit existing ones.
                                                    </li>
                                                    <li>
                                                        <strong>Meetings Attended:</strong> Updated in the <strong>Meetings</strong> section when members are added as attendees or visitors.
                                                    </li>
                                                </ul>

                                                <h6>Tips for Using Member Profiles</h6>
                                                <ul>
                                                    <li>
                                                        Ensure all data is accurate and up-to-date in the respective sections (e.g., membership history, roles, degrees, meetings).
                                                    </li>
                                                    <li>
                                                        Use the PDF export feature to create official records for sharing or archiving.
                                                    </li>
                                                    <li>
                                                        Regularly review and manage dropdown options (e.g., lodges, roles, ranks) via the <strong>Admin Panel</strong> to ensure consistency across profiles.
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Subsection: Meetings */}
                        <div className="subsection">
                            <button
                                className="subsection-toggle"
                                onClick={() => toggleSubsection("meetings-subsection")}
                            >
                                Meetings {expandedSubsection === "meetings-subsection" ? "-" : "+"}
                            </button>
                            {expandedSubsection === "meetings-subsection" && (
                                <div className="section-content">
                                    <h5>Meetings</h5>
                                    <p>
                                        The <strong>Meetings</strong> section allows users to view, add, edit, and delete meeting records. Meetings are organized by lodge and can include various event types (e.g., regular meetings, special occasions). Users can also manage attendees for each meeting, including members and visitors.
                                    </p>

                                    <h6>Features Overview</h6>
                                    <ul>
                                        <li>
                                            <strong>View Meetings:</strong> Displays all scheduled meetings. Users can filter meetings by lodge and view details like event type, date, description, and linked documents.
                                        </li>
                                        <li>
                                            <strong>Add/Edit Meetings:</strong> Allows users to create new meetings or update existing ones, specifying:
                                            <ul>
                                                <li>Lodge</li>
                                                <li>Event type</li>
                                                <li>Meeting date</li>
                                                <li>Description</li>
                                                <li>Document IDs</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Manage Attendees:</strong> Enables users to add or remove attendees, including members and visitors. Visitor details include their lodge affiliation.
                                        </li>
                                        <li>
                                            <strong>Delete Meetings:</strong> Users can delete meetings if necessary, after confirming the action.
                                        </li>
                                    </ul>

                                    <h6>Step-by-Step Instructions</h6>

                                    <h6>Viewing and Filtering Meetings</h6>
                                    <ol>
                                        <li>Use the <strong>Filter by Lodge</strong> dropdown to select a lodge and view its meetings. Select "All Lodges" to view all meetings.</li>
                                        <li>Each meeting displays:
                                            <ul>
                                                <li>The lodge name</li>
                                                <li>Event type</li>
                                                <li>Date</li>
                                                <li>Description</li>
                                                <li>Linked documents (if any)</li>
                                            </ul>
                                        </li>
                                    </ol>

                                    <h6>Adding or Editing Meetings</h6>
                                    <ol>
                                        <li>To add a meeting, click the <strong>Add New Meeting</strong> button.</li>
                                        <li>Fill out the form fields, including:
                                            <ul>
                                                <li><strong>Lodge:</strong> Select the lodge hosting the meeting.</li>
                                                <li><strong>Event Type:</strong> Choose the type of event from the dropdown.</li>
                                                <li><strong>Meeting Date:</strong> Set the meeting date.</li>
                                                <li><strong>Description:</strong> Optionally add details about the meeting.</li>
                                                <li><strong>Document IDs:</strong> Optionally link documents to the meeting by entering their IDs (comma-separated).</li>
                                            </ul>
                                        </li>
                                        <li>To edit a meeting, click the <strong>Edit</strong> button next to the meeting, update the fields, and click <strong>Save</strong>.</li>
                                    </ol>

                                    <h6>Deleting Meetings</h6>
                                    <ol>
                                        <li>Click the <strong>Delete</strong> button next to the meeting.</li>
                                        <li>Confirm the action to remove the meeting.</li>
                                    </ol>

                                    {/* Sub-Subsection: Manage Attendees */}
                                    <div className="sub-subsection">
                                        <button
                                            className="subsection-toggle"
                                            onClick={() => toggleSubSubsection("manage-attendees")}
                                        >
                                            Manage Attendees {expandedSubSubsection === "manage-attendees" ? "-" : "+"}
                                        </button>
                                        {expandedSubSubsection === "manage-attendees" && (
                                            <div className="subsection-content">
                                                <h5>Manage Attendees</h5>
                                                <p>
                                                    The <strong>Manage Attendees</strong> section lets users add or remove attendees for a selected meeting. Attendees can be members or visitors, with visitors requiring additional details.
                                                </p>

                                                <h6>Adding Attendees</h6>
                                                <ol>
                                                    <li>Select a meeting from the list and click <strong>Manage Attendees</strong>.</li>
                                                    <li>In the <strong>Attendees Form</strong>, select:
                                                        <ul>
                                                            <li><strong>Member:</strong> Choose a member from the dropdown.</li>
                                                            <li><strong>Visitor:</strong> Check the "Visitor" option to add a non-member. Specify:
                                                                <ul>
                                                                    <li><strong>Visitor's Lodge:</strong> Select the lodge the visitor represents.</li>
                                                                    <li><strong>Visitor's Name:</strong> Automatically populated based on the selected member or can be entered manually.</li>
                                                                </ul>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li>Click <strong>Add Attendee</strong> to save the attendee.</li>
                                                </ol>

                                                <h6>Removing Attendees</h6>
                                                <ol>
                                                    <li>From the attendee list, locate the attendee you wish to remove.</li>
                                                    <li>Click <strong>Remove</strong> next to their name and confirm the action.</li>
                                                </ol>

                                                <h6>Tips for Managing Attendees</h6>
                                                <ul>
                                                    <li>Ensure visitors have a valid lodge affiliation.</li>
                                                    <li>Review the attendee list for accuracy before finalizing meeting details.</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>


                        {/* Subsection: Statistics */}
                        <div className="subsection">
                            <button
                                className="subsection-toggle"
                                onClick={() => toggleSubsection("statistics-subsection")}
                            >
                                Statistics {expandedSubsection === "statistics-subsection" ? "-" : "+"}
                            </button>
                            {expandedSubsection === "statistics-subsection" && (
                                <div className="subsection-content">
                                    <h5>Meeting Statistics</h5>
                                    <p>The <strong>Statistics</strong> section provides insights into meetings, including attendance trends, lodge interactions, and event types. It offers tools like filtering, Sankey diagrams, and graphical breakdowns to analyze meeting data effectively.</p>

                                    <h6>Features Overview</h6>
                                    <ul>
                                        <li>
                                            <strong>Filter Meetings:</strong> Refine statistics by date range, lodge, and event type using intuitive filters.
                                        </li>
                                        <li>
                                            <strong>Attendance Trends:</strong> Visualize member and visitor attendance trends with monthly breakdowns.
                                        </li>
                                        <li>
                                            <strong>Lodge Interactions:</strong> Analyze visitor and meeting lodge interactions through Sankey diagrams.
                                        </li>
                                        <li>
                                            <strong>Breakdowns:</strong> View detailed statistics on:
                                            <ul>
                                                <li>Lodge-specific meeting and attendance data</li>
                                                <li>Event types and their attendance metrics</li>
                                                <li>Top visitor lodges based on attendance</li>
                                            </ul>
                                        </li>
                                        <li>
                                            <strong>Save and Export:</strong> Save Sankey diagrams as images for presentations or reports.
                                        </li>
                                    </ul>

                                    <h6>Step-by-Step Instructions</h6>

                                    <h6>Filtering Statistics</h6>
                                    <ol>
                                        <li>Use the <strong>Start Date</strong> and <strong>End Date</strong> fields to specify the date range for analysis.</li>
                                        <li>Select a lodge from the <strong>Lodge Filter</strong> dropdown to view data for a specific lodge, or choose "All Lodges" for aggregated data.</li>
                                        <li>Pick an event type from the <strong>Event Type Filter</strong> dropdown to narrow results to specific events.</li>
                                    </ol>

                                    <h6>Analyzing Attendance Trends</h6>
                                    <ol>
                                        <li>Scroll to the <strong>Monthly Breakdown</strong> section to view a bar chart comparing member and visitor attendance.</li>
                                        <li>Hover over the bars in the chart to see exact attendance counts.</li>
                                        <li>Refer to the table below the chart for additional details, including average attendance per meeting.</li>
                                    </ol>

                                    <h6>Viewing Lodge Interactions</h6>
                                    <ol>
                                        <li>Click the <strong>Visitor Sankey</strong> button to open the Sankey diagram modal.</li>
                                        <li>Inspect interactions between visitor lodges and meeting lodges visually.</li>
                                        <li>Adjust the diagram size using the <strong>Increase Size</strong> and <strong>Reduce Size</strong> buttons.</li>
                                        <li>Click <strong>Save Chart</strong> to download the Sankey diagram as an image.</li>
                                    </ol>

                                    <h6>Accessing Detailed Breakdowns</h6>
                                    <ol>
                                        <li>Scroll to the following sections to analyze additional statistics:
                                            <ul>
                                                <li><strong>Lodge Breakdown:</strong> Meetings and attendance by lodge.</li>
                                                <li><strong>Event Type Breakdown:</strong> Statistics grouped by event type.</li>
                                                <li><strong>Top Visitor Lodges:</strong> A ranking of lodges based on visitor counts.</li>
                                            </ul>
                                        </li>
                                    </ol>

                                    <h6>Viewing Meeting-Specific Attendees</h6>
                                    <ol>
                                        <li>Locate a meeting in the list at the bottom of the modal.</li>
                                        <li>Click <strong>Show Details</strong> to view the attendees for that meeting, including their visitor status and lodge affiliation.</li>
                                    </ol>

                                    <h6>Tips</h6>
                                    <ul>
                                        <li>Ensure date ranges are valid and cover the desired analysis period.</li>
                                        <li>Use the Sankey diagram to understand patterns of lodge visitation effectively.</li>
                                        <li>Export key charts for inclusion in reports or presentations.</li>
                                    </ul>
                                </div>
                            )}
                        </div>




                    </div>
                )}
            </div>


            {/* View Documents Section */}
            <div className="instruction-section">
                <button
                    className="section-toggle"
                    onClick={() => toggleSection("view-documents")}
                >
                    View Documents {expandedSection === "view-documents" ? "-" : "+"}
                </button>
                {expandedSection === "view-documents" && (
                    <div className="section-content">
                        <h2>View Documents</h2>
                        <p>
                            Access the <strong>View Documents</strong> section to browse, filter, and manage documents:
                        </p>
                        <ul>
                            <li>
                                <strong>Filter Documents:</strong> Use dropdowns and input fields to narrow down the results by
                                attributes such as <strong>ID</strong>, <strong>Title</strong>, <strong>Constitution</strong>,
                                <strong>Country</strong>, <strong>Order Code</strong>, <strong>Entity</strong>,
                                <strong>Event Type</strong>, <strong>Document Type</strong>, and more.
                            </li>
                            <li>
                                <strong>View and Download:</strong> Browse document details in a table format. If you have access,
                                click the <strong>Download</strong> button to save the document locally. Restricted files will
                                display "No Access."
                            </li>
                            <li>
                                <strong>Manage Access:</strong> Users with appropriate roles (e.g., Administrators or Archivists)
                                can modify access roles for specific documents by clicking the <strong>Change Access</strong> button.
                            </li>
                            <li>
                                <strong>Delete Documents:</strong> Administrators and Archivists can delete documents using the
                                <strong>Delete</strong> button.
                            </li>
                            <li>
                                <strong>Pagination:</strong> Navigate through the document list using the
                                <strong>Previous</strong> and <strong>Next</strong> buttons. Adjust the page size for more control.
                            </li>
                        </ul>
                        <p>
                            <strong>Tips:</strong> Combine multiple filters for precise searches. Hover over fields to copy their
                            content for quick reference.
                        </p>
                    </div>
                )}
            </div>

            {/* Upload Documents Section */}
            <div className="instruction-section">
                <button
                    className="section-toggle"
                    onClick={() => toggleSection("upload-documents")}
                >
                    Upload Documents {expandedSection === "upload-documents" ? "-" : "+"}
                </button>
                {expandedSection === "upload-documents" && (
                    <div className="section-content">
                        <h2>Upload Documents</h2>
                        <p>
                            The <strong>Upload Documents</strong> section allows authorized users to upload and manage documents with detailed metadata.
                        </p>
                        <ul>
                            <li>
                                <strong>Select a File:</strong> Use the <em>Choose File</em> button to select a document from your device for upload.
                            </li>
                            <li>
                                <strong>Enter Metadata:</strong> Fill out fields such as <em>Title</em>, <em>Description</em>, <em>Event Type</em>, <em>Document Type</em>, and others. Some fields, such as <em>Constitution</em> and <em>Country</em>, may have dropdowns for easy selection.
                            </li>
                            <li>
                                <strong>Specify Roles:</strong> Assign access permissions by selecting appropriate roles from the <em>Roles</em> dropdown. At least one role must be selected.
                            </li>
                            <li>
                                <strong>Review and Submit:</strong> Ensure all required fields are completed, then click <strong>Upload Document</strong> to submit the file. Any validation errors will be highlighted for correction.
                            </li>
                            <li>
                                <strong>Post-Submission:</strong> After a successful upload, the form will reset. You can view the uploaded document in the <strong>View Documents</strong> section, subject to your access permissions.
                            </li>
                        </ul>
                        <p>
                            <strong>Note:</strong> Uploaded documents may require admin approval to be visible to other users.
                        </p>
                    </div>
                )}
            </div>



            {/* Admin Section */}
            <div className="instruction-section">
                <button
                    className="section-toggle"
                    onClick={() => toggleSection("admin-section")}
                >
                    Admin {expandedSection === "admin-section" ? "-" : "+"}
                </button>
                {expandedSection === "admin-section" && (
                    <div className="section-content">
                        <h2>Admin</h2>
                        <p>
                            The <strong>Admin</strong> section offers tools for managing platform data, users, and configurations. It is accessible to authorized users with elevated permissions, such as <em>Admin</em> or <em>Archivist</em> roles.
                        </p>
                        <h3>Key Features</h3>
                        <ul>
                            <li>
                                <strong>Manage Records:</strong> Use the dropdown to select a table (e.g., Constitutions, Countries, Lodges, etc.), view existing entries, and perform actions such as editing or deleting records.
                            </li>
                            <li>
                                <strong>Add New Entries:</strong> Click <em>Add New</em> to create a new entry for the selected table. Fill out the required fields in the form, then save your changes.
                            </li>
                            <li>
                                <strong>Edit Entries:</strong> Click the <em>Edit</em> button next to an existing entry to modify its details. Save the changes when done.
                            </li>
                            <li>
                                <strong>Delete Entries:</strong> Remove an entry by clicking the <em>Delete</em> button. Confirm your action when prompted.
                            </li>
                            <li>
                                <strong>Manage Users:</strong> In the <em>Users</em> table, assign roles, update user details, or delete user accounts. Role changes are available via a dropdown menu in the <em>Role</em> column.
                            </li>
                            <li>
                                <strong>Confirmation Modals:</strong> The Admin section uses confirmation prompts for critical actions such as saving or deleting data to prevent accidental modifications.
                            </li>
                        </ul>
                        <p>
                            <strong>Note:</strong> Ensure proper permissions are assigned to users and changes are made responsibly, as they impact the platform's functionality.
                        </p>
                    </div>
                )}
            </div>

        </div>
    );
}

export default InstructionsPage;
