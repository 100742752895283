import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DocumentViewer.css'; // Add your styling here
import { logInteraction } from "../utils/logInteraction"; // Import the logging function
import Select from "react-select"; // Import react-select

function DocumentViewer({ user }) {
    useEffect(() => {
        logInteraction("page_view", { viewport: { width: window.innerWidth, height: window.innerHeight } }, user);
    }, [user]);

    const [documents, setDocuments] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [roles, setRoles] = useState([]);
    const [newRoles, setNewRoles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [documentCounts, setDocumentCounts] = useState(null); // Holds counts
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const [pageSize, setPageSize] = useState(10); // Number of items per page
    const [totalDocuments, setTotalDocuments] = useState(0); // Total number of documents

    const fetchDocumentCounts = async (filters = {}) => {
        try {
            const response = await axios.get('/api/document-counts', { params: filters });
            setDocumentCounts(response.data.counts); // Save counts in state
        } catch (error) {
            console.error('Error fetching document counts:', error);
        }
    };
    useEffect(() => {
        fetchDocumentCounts(); // Load counts without filters on page load
    }, []);

    const handleOpenModal = async (docId) => {
        try {
            const response = await axios.get(`/api/document-roles/${docId}`);
            setRoles(response.data.roles);
            setNewRoles(response.data.roles); // Prepopulate with current roles
            setSelectedDoc(docId);
            setShowModal(true);
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const handleRoleChange = (role) => {
        setNewRoles((prev) => {
            if (prev.includes(role)) {
                return prev.filter((r) => r !== role);
            } else {
                return [...prev, role];
            }
        });
    };

    const handleSaveRoles = async () => {
        try {
            await axios.post(`/api/document-roles/${selectedDoc}`, { roles: newRoles });
            alert('Roles updated successfully!');
            setShowModal(false);
            handleSearch(); // Refresh document list
        } catch (error) {
            console.error('Error updating roles:', error);
        }
    };

    const [dropdowns, setDropdowns] = useState({
        constitutions: [],
        countries: [],
        orderCodes: [],
        lodges: [],
        eventTypes: [],
        documentTypes: [],
        fileTypes: [],
        ids: [], // Add this
        titles: [] // Add this
    });

    const [filterCriteria, setFilterCriteria] = useState({
        id: '', // Add the ID field to filter criteria
        title: '',
        constitution: '',
        country: '',
        orderCode: '',
        lodge: '',
        eventType: '',
        documentType: '',
        fileType: '',
        date: '',
        version: '',
        addendum: '',
        author_initials: '',
        doi: '',
        filename: '',
        description: ''
    });

    useEffect(() => {
        const fetchDropdownData = async () => {
            try {
                const [
                    constitutions,
                    countries,
                    orderCodes,
                    lodges,
                    eventTypes,
                    documentTypes,
                    fileTypes,
                    ids,
                    titles
                ] = await Promise.all([
                    axios.get("/api/constitutions"),
                    axios.get("/api/countries"),
                    axios.get("/api/order-codes"),
                    axios.get("/api/lodges"),
                    axios.get("/api/event-types"),
                    axios.get("/api/document-types"),
                    axios.get("/api/file-types"),
                    axios.get("/api/document-ids"),
                    axios.get("/api/document-titles"),
                ]);

                setDropdowns({
                    constitutions: constitutions.data.map((item) => ({
                        value: item.code,
                        label: item.description,
                    })),
                    countries: countries.data,
                    orderCodes: orderCodes.data,
                    lodges: lodges.data,
                    eventTypes: eventTypes.data,
                    documentTypes: documentTypes.data,
                    fileTypes: fileTypes.data,
                    ids: ids.data, // Preloaded document IDs
                    titles: titles.data, // Preloaded document titles
                });
            } catch (error) {
                console.error("Error fetching dropdown data:", error);
            }
        };

        fetchDropdownData();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilterCriteria({ ...filterCriteria, [name]: value });
    };

    const fetchDocumentRoles = async () => {
        try {
            const response = await axios.get('/api/document-roles', {
                params: { role: user.role }, // Use user.role directly
            });

            return response.data; // Array of { document_id, hasAccess }
        } catch (error) {
            console.error('Error fetching document roles:', error);
            return [];
        }
    };

    const handleDeleteDocument = async (docId) => {
        const confirmed = window.confirm('Are you sure you want to delete this document? This action cannot be undone.');
        if (!confirmed) return;

        try {
            await axios.delete(`/api/documents/${docId}`);
            alert('Document deleted successfully!');
            handleSearch(); // Refresh the document list after deletion
        } catch (error) {
            console.error('Error deleting document:', error);
            alert('Failed to delete the document. Please try again.');
        }
    };


    // const handleSearch = async () => {
    //     try {
    //         const [documentsResponse, rolesResponse] = await Promise.all([
    //             axios.get('/api/documents', { params: filterCriteria }),
    //             fetchDocumentRoles(),
    //         ]);
    //
    //         const documentsWithAccess = documentsResponse.data.map((doc) => {
    //             const accessData = rolesResponse.find((role) => role.id === doc.id);
    //             return {
    //                 ...doc,
    //                 hasAccess: accessData ? accessData.hasAccess : false,
    //             };
    //         });
    //
    //         setDocuments(documentsWithAccess);
    //
    //         // Fetch updated counts with current filters
    //         fetchDocumentCounts(filterCriteria);
    //     } catch (error) {
    //         console.error('Error fetching documents or roles:', error);
    //     }
    // };

    const handleSearch = async () => {
        try {
            const offset = (currentPage - 1) * pageSize;
            const [documentsResponse, rolesResponse] = await Promise.all([
                axios.get('/api/documents', {
                    params: {
                        ...filterCriteria,
                        limit: pageSize,
                        offset,
                    },
                }),
                fetchDocumentRoles(),
            ]);

            const documentsWithAccess = documentsResponse.data.documents.map((doc) => {
                const accessData = rolesResponse.find((role) => role.id === doc.id);
                return {
                    ...doc,
                    hasAccess: accessData ? accessData.hasAccess : false,
                };
            });

            setDocuments(documentsWithAccess);
            setTotalDocuments(documentsResponse.data.total_count); // Update total documents count

            // Fetch updated counts with current filters
            fetchDocumentCounts(filterCriteria);
        } catch (error) {
            console.error('Error fetching documents or roles:', error);
        }
    };

    const serverPath = '/opt/websites/freemasonry2/express/documents/';

    const convertToSAST = (utcDate) => {
        const date = new Date(utcDate);
        date.setHours(date.getHours() + 2);
        return date.toISOString().split('T')[0];
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert(`Copied to clipboard: ${text}`);
        }).catch((error) => {
            console.error('Failed to copy text to clipboard:', error);
        });
    };


// Trigger search automatically when filterCriteria changes
//     useEffect(() => {
//         handleSearch();
//     }, [filterCriteria]);


    // const handleDropdownChange = (field, value) => {
    //     setFilterCriteria((prevCriteria) => ({
    //         ...prevCriteria,
    //         [field]: value,
    //     }));
    // };

    useEffect(() => {
        handleSearch();
    }, [currentPage, pageSize]);

    useEffect(() => {
        setCurrentPage(1); // Reset to the first page
        handleSearch();
    }, [filterCriteria]);

    const handleDropdownChange = (field, value) => {
        setFilterCriteria((prev) => ({ ...prev, [field]: value }));
    };


    return (
        <div className="container">
            <h2>Document Viewer</h2>
            <div className="counts-summary">
                {documentCounts ? (
                    <h3>Number of Documents: {documentCounts.total_count}</h3>
                ) : (
                    <p>Loading document counts...</p>
                )}
            </div>
            <div className="filter-form2">
                <div>
                    <label>ID:</label>
                    <Select
                        menuPortalTarget={document.body} // Ensures dropdown is rendered in the body
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.ids.map((id) => ({
                            value: id.value,
                            label: id.label,
                        }))} // Use preloaded IDs
                        onChange={(selectedOption) => {
                            handleDropdownChange(
                                "id",
                                selectedOption ? selectedOption.value : ""
                            );
                        }}
                        value={
                            dropdowns.ids.find(
                                (option) => option.value === filterCriteria.id
                            )
                                ? {
                                    value: filterCriteria.id,
                                    label: dropdowns.ids.find(
                                        (id) => id.value === filterCriteria.id
                                    ).label,
                                }
                                : null
                        }
                        isSearchable
                        isClearable
                        placeholder="Select ID"
                    />
                </div>
                <div>
                    <label>Title:</label>
                    <Select


                        // menuPortalTarget={document.body} // Ensures dropdown is rendered in the body
                        // menuPlacement="auto"
                        // maxMenuHeight={200}
                        options={dropdowns.titles.map((title) => ({
                            value: title.value,
                            label: title.label,
                        }))} // Use preloaded Titles
                        onChange={(selectedOption) => {
                            handleDropdownChange(
                                "title",
                                selectedOption ? selectedOption.value : ""
                            );
                        }}
                        value={
                            dropdowns.titles.find(
                                (option) => option.value === filterCriteria.title
                            )
                                ? {
                                    value: filterCriteria.title,
                                    label: dropdowns.titles.find(
                                        (title) => title.value === filterCriteria.title
                                    ).label,
                                }
                                : null
                        }
                        isSearchable
                        isClearable
                        placeholder="Select Title"

                    />
                </div>
                <div>
                    <label>Constitution:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.constitutions} // Use preprocessed dropdown options
                        onChange={(selectedOption) => {
                            handleDropdownChange(
                                "constitution",
                                selectedOption ? selectedOption.value : ""
                            );
                        }}
                        value={
                            dropdowns.constitutions.find(
                                (option) => option.value === filterCriteria.constitution
                            ) || null
                        }
                        isSearchable
                        isClearable
                        placeholder="Select Constitution"


                    />
                </div>

                <div>
                    <label>Country:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.countries.map((country) => ({
                            value: country.code,
                            label: country.name,
                        }))}
                        onChange={(selectedOption) => {
                            handleDropdownChange(
                                "country",
                                selectedOption ? selectedOption.value : ""
                            );
                        }}
                        value={
                            dropdowns.countries.find(
                                (option) => option.code === filterCriteria.country
                            )
                                ? {
                                    value: filterCriteria.country,
                                    label: dropdowns.countries.find(
                                        (country) => country.code === filterCriteria.country
                                    ).name,
                                }
                                : null
                        }
                        isSearchable
                        isClearable
                        placeholder="Select Country"


                    />
                </div>
                <div>
                    <label>Order Code:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.orderCodes.map((orderCode) => ({
                            value: orderCode.code,
                            label: orderCode.description,
                        }))}
                        onChange={(selectedOption) =>
                            handleDropdownChange(
                                "orderCode",
                                selectedOption ? selectedOption.value : ""
                            )
                        }
                        value={
                            dropdowns.orderCodes.find(
                                (option) => option.code === filterCriteria.orderCode
                            )
                                ? {
                                    value: filterCriteria.orderCode,
                                    label: dropdowns.orderCodes.find(
                                        (orderCode) =>
                                            orderCode.code === filterCriteria.orderCode
                                    ).description,
                                }
                                : null
                        }
                        isClearable
                        placeholder="Select Order Code"

                    />
                </div>
                <div>
                    <label>Entity:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.lodges.map((lodge) => ({
                            value: lodge.code,
                            label: lodge.name,
                        }))}
                        onChange={(selectedOption) =>
                            handleDropdownChange(
                                "lodge",
                                selectedOption ? selectedOption.value : ""
                            )
                        }
                        value={
                            dropdowns.lodges.find(
                                (option) => option.code === filterCriteria.lodge
                            )
                                ? {
                                    value: filterCriteria.lodge,
                                    label: dropdowns.lodges.find(
                                        (lodge) => lodge.code === filterCriteria.lodge
                                    ).name,
                                }
                                : null
                        }
                        isClearable
                        placeholder="Select Entity"
                        menuPortalTarget={document.body} // Avoids overflow issues

                    />
                </div>
                <div>
                    <label>Event Type:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.eventTypes.map((eventType) => ({
                            value: eventType.code,
                            label: eventType.description,
                        }))}
                        onChange={(selectedOption) =>
                            handleDropdownChange(
                                "eventType",
                                selectedOption ? selectedOption.value : ""
                            )
                        }
                        value={
                            dropdowns.eventTypes.find(
                                (option) => option.code === filterCriteria.eventType
                            )
                                ? {
                                    value: filterCriteria.eventType,
                                    label: dropdowns.eventTypes.find(
                                        (eventType) => eventType.code === filterCriteria.eventType
                                    ).description,
                                }
                                : null
                        }
                        isClearable
                        placeholder="Select Event Type"

                    />
                </div>
                <div>
                    <label>Document Type:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.documentTypes.map((docType) => ({
                            value: docType.code,
                            label: docType.description,
                        }))}
                        onChange={(selectedOption) =>
                            handleDropdownChange(
                                "documentType",
                                selectedOption ? selectedOption.value : ""
                            )
                        }
                        value={
                            dropdowns.documentTypes.find(
                                (option) => option.code === filterCriteria.documentType
                            )
                                ? {
                                    value: filterCriteria.documentType,
                                    label: dropdowns.documentTypes.find(
                                        (docType) => docType.code === filterCriteria.documentType
                                    ).description,
                                }
                                : null
                        }
                        isClearable
                        placeholder="Select Document Type"

                    />
                </div>
                <div>
                    <label>File Type:</label>
                    <Select
                        className="single-select"
                        classNamePrefix="react-select"
                        menuPlacement="auto"
                        maxMenuHeight={200}
                        options={dropdowns.fileTypes.map((fileType) => ({
                            value: fileType.code,
                            label: fileType.description,
                        }))}
                        onChange={(selectedOption) =>
                            handleDropdownChange(
                                "fileType",
                                selectedOption ? selectedOption.value : ""
                            )
                        }
                        value={
                            dropdowns.fileTypes.find(
                                (option) => option.code === filterCriteria.fileType
                            )
                                ? {
                                    value: filterCriteria.fileType,
                                    label: dropdowns.fileTypes.find(
                                        (fileType) => fileType.code === filterCriteria.fileType
                                    ).description,
                                }
                                : null
                        }
                        isClearable
                        placeholder="Select File Type"

                    />
                </div>
                <div>
                    <label>Description:</label>
                    <input
                        type="text"
                        name="description"
                        value={filterCriteria.description}
                        onChange={handleInputChange}
                        placeholder="Search by description"
                    />
                </div>
                <div>
                    <label>Date:</label>
                    <input
                        type="date"
                        name="date"
                        value={filterCriteria.date}
                        onChange={handleInputChange}
                    />
                </div>
                <div>
                    <label>Version:</label>
                    <input
                        type="text"
                        name="version"
                        value={filterCriteria.version}
                        onChange={handleInputChange}
                        placeholder="Version"
                    />
                </div>
                <div>
                    <label>Addendum:</label>
                    <input
                        type="text"
                        name="addendum"
                        value={filterCriteria.addendum}
                        onChange={handleInputChange}
                        placeholder="Addendum"
                    />
                </div>
                <div>
                    <label>Author(s):</label>
                    <input
                        type="text"
                        name="author_initials"
                        value={filterCriteria.author_initials}
                        onChange={handleInputChange}
                        placeholder="Author(s)"
                    />
                </div>
                {/*<div>*/}
                {/*    <label>DOI:</label>*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        name="doi"*/}
                {/*        value={filterCriteria.doi}*/}
                {/*        onChange={handleInputChange}*/}
                {/*        placeholder="DOI"*/}
                {/*    />*/}
                {/*</div>*/}
                <div>
                    <label>Filename:</label>
                    <input
                        type="text"
                        name="filename"
                        value={filterCriteria.filename}
                        onChange={handleInputChange}
                        placeholder="Filename"
                    />
                </div>
                {/*<button onClick={handleSearch}>Search</button>*/}
            </div>
            <div className="pagination-controls">
                <button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                >
                    Previous
                </button>
                <span>
        Page {currentPage} of {Math.ceil(totalDocuments / pageSize)}
    </span>
                <button
                    disabled={currentPage === Math.ceil(totalDocuments / pageSize)}
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                >
                    Next
                </button>
            </div>
            <div className="document-list">
                <h3>Documents</h3>
                {documents.length > 0 ? (
                    <div className="scroll-container">
                        <table>
                            <thead>
                            <tr>
                                <th>ID</th> {/* Add ID column */}
                                <th>Title</th>
                                <th>Constitution</th>
                                <th>Country</th>
                                <th>Order Code</th>
                                <th>Lodge</th>
                                <th>Event Type</th>
                                <th>Document Type</th>
                                <th>File Type</th>
                                <th>Date</th>
                                <th>Version</th>
                                <th>Addendum</th>
                                <th>Author(s)</th>
                                <th>DOI</th>
                                <th>Filename</th>
                                <th>Description</th>
                                <th>Actions</th>
                                {user.role === 'Admin' || user.role === 'Archivist' ? <th>Access</th> : null}
                                {user.role === 'Admin' || user.role === 'Archivist' ? <th>Delete</th> : null}
                            </tr>
                            </thead>
                            <tbody>
                            {documents.map((doc) => {
                                const relativePath = doc.file_path.replace(serverPath, '');
                                return (
                                    <tr
                                        key={doc.id}
                                        className={!doc.hasAccess ? 'no-access' : ''}
                                    >

                                        <td>{doc.id}</td> {/* Display document ID */}
                                        <td>{doc.title}</td>
                                        <td>{doc.constitution}</td>
                                        <td>{doc.country}</td>
                                        <td>{doc.order_code_description}</td>
                                        <td>{doc.lodge}</td>
                                        <td>{doc.event_type_description}</td>
                                        <td>{doc.document_type_description}</td>
                                        <td>{doc.file_type_description}</td>
                                        <td>{convertToSAST(doc.date_created)}</td>
                                        <td>{doc.version}</td>
                                        <td>{doc.addendum}</td>
                                        <td>{doc.author_initials}</td>
                                        <td>{doc.doi}</td>
                                        <td>{doc.filename}</td>
                                        <td>{doc.description}</td>
                                        {/*{Object.keys(doc).map((key) => (*/}
                                        {/*    <td*/}
                                        {/*        key={key}*/}
                                        {/*        onMouseEnter={() => copyToClipboard(doc[key])}*/}
                                        {/*        style={{ cursor: "pointer", textDecoration: "underline" }}*/}
                                        {/*        title={`Click to copy ${doc[key]}`}*/}
                                        {/*    >*/}
                                        {/*        {doc[key]}*/}
                                        {/*    </td>*/}
                                        {/*))}*/}
                                        <td>
                                            {doc.hasAccess ? (
                                                <a href={`${window.location.origin}/documents/${relativePath}`} download>
                                                    Download
                                                </a>
                                            ) : (
                                                <span style={{ color: 'gray' }}>No Access</span>
                                            )}
                                        </td>

                                        <td>
                                            {(user.role === 'Admin' || user.role === 'Archivist') && (
                                                <button onClick={() => handleOpenModal(doc.id)}>Change Access</button>
                                            )}
                                        </td>
                                        <td>
                                            {(user.role === 'Admin' || user.role === 'Archivist') && (
                                                <button onClick={() => handleDeleteDocument(doc.id)} style={{ marginLeft: '10px', backgroundColor: '#dc3545', color: 'white' }}>
                                                    Delete
                                                </button>
                                            )}
                                        </td>

                                        {/*<td>*/}
                                        {/*    <a href={`${window.location.origin}/documents/${relativePath}`} download>*/}
                                        {/*        Download*/}
                                        {/*    </a>*/}
                                        {/*</td>*/}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>

                        {showModal && (
                            <div className="modal">
                                <div className="modal-content">
                                    <h3>Change Access for Document {selectedDoc}</h3>
                                    <ul>
                                        {['Admin', 'Archivist', 'Member', 'Researcher'].map((role) => (
                                            <li key={role}>
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={newRoles.includes(role)}
                                                        onChange={() => handleRoleChange(role)}
                                                    />
                                                    {role}
                                                </label>
                                            </li>
                                        ))}
                                    </ul>
                                    <button onClick={handleSaveRoles}>Save</button>
                                    <button onClick={() => setShowModal(false)}>Cancel</button>
                                </div>
                            </div>
                        )}



                    </div>
                ) : (
                    <p>No documents found.</p>
                )}
            </div>
        </div>
    );
}

export default DocumentViewer;
