import React, { useState, useEffect } from "react";
import axios from "axios";
import "./LodgeDetailsModal.css";
import Select from "react-select";

function LodgeDetailsModal({ onClose, onSave, onDelete }) {
    const [lodges, setLodges] = useState([]);
    const [buildings, setBuildings] = useState([]);
    const [lodgeTypes, setLodgeTypes] = useState([]);
    const [constitutions, setConstitutions] = useState([]);
    const [formData, setFormData] = useState({});
    const [selectedLodge, setSelectedLodge] = useState(null);
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [errors, setErrors] = useState({});

    // Fetch data on component mount
    useEffect(() => {
        fetchLodges();
        fetchBuildings();
        fetchLodgeTypes();
        fetchConstitutions();
    }, []);

    const fetchLodges = async () => {
        try {
            const res = await axios.get("/api/resources/lodges");
            setLodges(res.data);
        } catch (error) {
            console.error("Error fetching lodges:", error);
        }
    };

    const fetchBuildings = async () => {
        try {
            const res = await axios.get("/api/buildings");
            setBuildings(res.data);
        } catch (error) {
            console.error("Error fetching buildings:", error);
        }
    };

    const fetchLodgeTypes = async () => {
        try {
            const res = await axios.get("/api/resources/lodge-types");
            setLodgeTypes(res.data);
        } catch (error) {
            console.error("Error fetching lodge types:", error);
        }
    };

    const fetchConstitutions = async () => {
        try {
            const res = await axios.get("/api/resources/constitutions");
            setConstitutions(res.data);
        } catch (error) {
            console.error("Error fetching constitutions:", error);
        }
    };

    const handleLodgeSelect = async (id) => {
        if (id === "new") {
            setSelectedLodge(null);
            setFormData({});
            setIsAddingNew(true);
            return;
        }

        try {
            const res = await axios.get(`/api/resources/lodges/${id}`);
            setSelectedLodge(res.data);
            setFormData({
                ...res.data,
                building_id: res.data.building_id || "",
                warrant_document_id: (res.data.warrant_document_id || []).join(", "),
                general_document_id: (res.data.general_document_id || []).join(", "),
                year_decommissioned: res.data.year_decommissioned || "",
            });
            setIsAddingNew(false);
        } catch (error) {
            console.error("Error fetching lodge details:", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target || {}; // Handle both normal input and react-select
        setFormData({
            ...formData,
            [name]: value,
        });

        if (errors[name]) {
            setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
        }
    };

    const validateForm = () => {
        const requiredFields = ["code", "name", "year_established", "status", "lodge_type_id", "constitution_id", "building_id"];
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!formData[field] || formData[field].toString().trim() === "") {
                newErrors[field] = `${field.replace(/_/g, " ")} is required.`;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            alert("Please fill in all required fields.");
            return;
        }

        try {
            const payload = {
                ...formData,
                warrant_document_id: formData.warrant_document_id
                    ? formData.warrant_document_id.split(",").map((id) => id.trim())
                    : [],
                general_document_id: formData.general_document_id
                    ? formData.general_document_id.split(",").map((id) => id.trim())
                    : [],
            };

            if (selectedLodge) {
                await axios.put(`/api/resources/lodges/${selectedLodge.id}`, payload);
            } else {
                await axios.post(`/api/resources/lodges`, payload);
            }

            fetchLodges();
            alert("Lodge saved successfully!");
            if (typeof onSave === "function") onSave();
        } catch (error) {
            console.error("Error saving lodge:", error);
            alert("Error saving lodge. Please try again.");
        }
    };



    return (
        <div className="lodge-modal-overlay">
            <div className="lodge-modal-content">
                <button className="lodge-close-btn" onClick={onClose}>
                    &times;
                </button>
                <h2>{isAddingNew ? "Select Lodge" : "Edit Lodge"}</h2>

                <div className="lodge-form-group">
                    <label>Select Lodge</label>
                    <Select
                        options={[
                            { value: "new", label: "Select Lodge" },
                            ...lodges.map((lodge) => ({
                                value: lodge.id,
                                label: lodge.name,
                            })),
                        ]}
                        onChange={(selectedOption) =>
                            handleLodgeSelect(selectedOption ? selectedOption.value : "new")
                        }
                        value={
                            isAddingNew
                                ? { value: "new", label: "Select Lodge" }
                                : selectedLodge
                                ? { value: selectedLodge.id, label: selectedLodge.name }
                                : null
                        }
                        isSearchable
                        isClearable
                        placeholder="Choose Lodge"
                    />
                </div>

                <div className="lodge-form-container">
                    {/* Lodge Type Dropdown */}
                    <div className="lodge-form-group">
                        <label>Lodge Type</label>
                        <Select
                            options={lodgeTypes.map((type) => ({
                                value: type.id,
                                label: type.description,
                            }))}
                            onChange={(selectedOption) =>
                                handleInputChange({ target: { name: "lodge_type_id", value: selectedOption ? selectedOption.value : "" } })
                            }
                            value={
                                lodgeTypes.find((type) => type.id === formData.lodge_type_id)
                                    ? {
                                        value: formData.lodge_type_id,
                                        label: lodgeTypes.find((type) => type.id === formData.lodge_type_id).description,
                                    }
                                    : null
                            }
                            isSearchable
                            isClearable
                            placeholder="Select Lodge Type"
                        />
                        {errors.lodge_type_id && <p className="error-message">{errors.lodge_type_id}</p>}
                    </div>

                    {/* Constitution Dropdown */}
                    <div className="lodge-form-group">
                        <label>Constitution</label>
                        <Select
                            options={constitutions.map((constitution) => ({
                                value: constitution.id,
                                label: constitution.description,
                            }))}
                            onChange={(selectedOption) =>
                                handleInputChange({ target: { name: "constitution_id", value: selectedOption ? selectedOption.value : "" } })
                            }
                            value={
                                constitutions.find((constitution) => constitution.id === formData.constitution_id)
                                    ? {
                                        value: formData.constitution_id,
                                        label: constitutions.find((constitution) => constitution.id === formData.constitution_id).description,
                                    }
                                    : null
                            }
                            isSearchable
                            isClearable
                            placeholder="Select Constitution"
                        />
                        {errors.constitution_id && <p className="error-message">{errors.constitution_id}</p>}
                    </div>

                    {/* Building Dropdown */}
                    <div className="lodge-form-group">
                        <label>Building</label>
                        <Select
                            options={buildings.map((building) => ({
                                value: building.id,
                                label: building.name,
                            }))}
                            onChange={(selectedOption) =>
                                handleInputChange({ target: { name: "building_id", value: selectedOption ? selectedOption.value : "" } })
                            }
                            value={
                                buildings.find((building) => building.id === formData.building_id)
                                    ? {
                                        value: formData.building_id,
                                        label: buildings.find((building) => building.id === formData.building_id).name,
                                    }
                                    : null
                            }
                            isSearchable
                            isClearable
                            placeholder="Select Building"
                        />
                        {errors.building_id && <p className="error-message">{errors.building_id}</p>}
                    </div>

                    {/* Other form fields */}
                    {[
                        { key: "code", label: "Lodge Code" },
                        { key: "name", label: "Lodge Name" },
                        { key: "year_established", label: "Year Established" },
                        { key: "status", label: "Status" },
                        { key: "warrant_document_id", label: "Warrant Document IDs (comma-separated)" },
                        { key: "general_document_id", label: "General Document IDs (comma-separated)" },
                        { key: "year_decommissioned", label: "Year Decommissioned" },
                        { key: "comments", label: "Comments" },
                    ].map(({ key, label }) => (
                        <div className="lodge-form-group" key={key}>
                            <label>{label}</label>
                            <input
                                type={key.includes("year") ? "text" : "text"}
                                name={key}
                                value={formData[key] || ""}
                                onChange={handleInputChange}
                                className={errors[key] ? "error" : ""}
                            />
                            {errors[key] && <p className="error-message">{errors[key]}</p>}
                        </div>
                    ))}
                </div>

                <div className="lodge-modal-actions">
                    {/*{selectedLodge && (*/}
                    {/*    <button className="lodge-delete-btn" onClick={handleDelete}>*/}
                    {/*        Delete*/}
                    {/*    </button>*/}
                    {/*)}*/}
                    <button className="lodge-save-btn" onClick={handleSave}>
                        Save
                    </button>
                    <button className="lodge-cancel-btn" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LodgeDetailsModal;
