import React from 'react';
import './Disclaimer.css';

const Disclaimer = () => {
    return (
        <div className="disclaimer-banner">
            <p>⚠️ This site is currently under construction. Content will be added soon. Stay tuned!</p>
        </div>
    );
};

export default Disclaimer;
