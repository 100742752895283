import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ user, allowedRoles, children }) => {
    if (!user) {
        // If not logged in, redirect to the home page
        return <Navigate to="/" state={{ unauthorized: true }} />;
    }

    if (allowedRoles && !allowedRoles.includes(user.role)) {
        // If the user's role is not in the list of allowed roles, redirect to the home page
        console.error('ProtectedRoute: User does not have the required role. Allowed:', allowedRoles, 'User role:', user.role);
        return <Navigate to="/" state={{ unauthorized: true }} />;
    }

    // If authorized, render the children
    return children;
};

export default ProtectedRoute;
