import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPanel';
import DocumentManagement from './pages/DocumentManagement';
import ViewDocuments from './pages/DocumentViewer';
import ProtectedRoute from './pages/ProtectedRoute';
import LoginRegisterPage from './pages/LoginRegisterPage';
import Footer from './pages/Footer';
import Disclaimer from './pages/Disclaimer';
import Layout from './components/Layout';

import ResearchPage from './pages/ResearchPage'; // Import for new Research page
import ResourcesPage from './pages/ResourcesPage'; // Import for new Resources page
import AboutPage from './pages/AboutPage'; // Import for new Contact page
import InstructionsPage from './pages/InstructionsPage'; // Import for new Contact page
import '@fortawesome/fontawesome-free/css/all.min.css';

Modal.setAppElement('#root');

function App() {
    const [user, setUser] = useState(() => JSON.parse(localStorage.getItem('user'))); // Load user from localStorage
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

    // Fetch the current user session
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get('/api/current_user');
                const userData = response.data;
                setUser(userData);
                localStorage.setItem('user', JSON.stringify(userData)); // Store user in localStorage
            } catch (error) {
                if (error.response?.status === 401) {
                    setUser(null);
                    localStorage.removeItem('user'); // Clear storage if not logged in
                } else {
                    console.error('Error fetching user:', error);
                }
            }
        };

        fetchUser();
    }, []);

    // Open and close login modal
    const openLoginModal = () => setIsLoginModalOpen(true);
    const closeLoginModal = () => setIsLoginModalOpen(false);

    // Handle logout
    const handleLogout = async () => {
        try {
            await axios.post('/api/logout'); // Call backend logout endpoint if available
            setUser(null);
            localStorage.removeItem('user'); // Clear local storage
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    return (
        <Router>
            <Disclaimer />
            <div className="App">
                <Routes>
                    {/* Home Page Route */}
                    <Route
                        path="/"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <HomePage user={user} openLoginModal={openLoginModal} />
                            </Layout>
                        }
                    />
                    {/* Research Page Route */}
                    <Route
                        path="/research"
                        element={
                            <Layout user={user} onLogout={handleLogout} openULoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={['User','Admin','Member', 'Archivist']}>
                                <ResearchPage />
                                </ProtectedRoute>
                            </Layout>
                        }
                    />
                    <Route
                        path="/instructions"
                        element={
                            <Layout user={user} onLogout={handleLogout} openULoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={['Admin', 'Archivist']}>
                                    <InstructionsPage />
                                </ProtectedRoute>
                            </Layout>
                        }
                    />




                    {/* Resources Page Route */}
                    {/* Resources Page Route */}
                    <Route
                        path="/resources"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={['Admin', 'Archivist']}>
                                    <ResourcesPage user={user} openLoginModal={openLoginModal} />
                                </ProtectedRoute>
                            </Layout>
                        }
                    />
                    {/* Contact Page Route */}
                    <Route
                        path="/About"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                {/*<ProtectedRoute user={user} allowedRoles={['User','Admin','Member', 'SU']}>*/}
                                <AboutPage />
                                {/*</ProtectedRoute>*/}
                            </Layout>
                        }
                    />
                    {/* Upload Documents Route */}
                    <Route
                        path="/upload-documents"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={['Admin','Archivist']}>
                                    <DocumentManagement user={user} />
                                </ProtectedRoute>
                            </Layout>
                        }
                    />
                    {/* View Documents Route */}
                    <Route
                        path="/view-documents"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={['Member','Admin', 'Archivist']}>
                                    <ViewDocuments user={user} />
                                </ProtectedRoute>
                            </Layout>
                        }
                    />
                    {/* admin panel Route */}
                    <Route
                        path="/admin"
                        element={
                            <Layout user={user} onLogout={handleLogout} openLoginModal={openLoginModal}>
                                <ProtectedRoute user={user} allowedRoles={['Admin','Archivist']}>
                                    <AdminPage user={user} />
                                </ProtectedRoute>
                            </Layout>
                        }
                    />

                </Routes>

                {/* Login/Register Modal */}
                <LoginRegisterPage
                    isOpen={isLoginModalOpen}
                    closeModal={closeLoginModal}
                    setUser={(newUser) => {
                        setUser(newUser);
                        localStorage.setItem('user', JSON.stringify(newUser)); // Update user in localStorage
                    }}
                />
            </div>
            <Footer />
        </Router>
    );
}

export default App;
